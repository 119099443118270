import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Image, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SetState, SendEmail } from '../../redux/reducers/auth';
import { Link } from 'react-router-dom';
var logo = require('../../assets/images/logo.png');
var logoUrl = logo.default;
var ForgotPassword = function () {
    var _a = useSelector(function (state) { return state.auth; }), emailSent = _a.emailSent, message = _a.message, loading = _a.loading;
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var _b = useState(''), email = _b[0], setEmail = _b[1];
    var _c = useState(''), err = _c[0], setErr = _c[1];
    var handleInputChange = function (event) {
        setEmail(event.target.value);
    };
    var handleSubmit = function (event) {
        event.preventDefault();
        if (email) {
            dispatch(SendEmail(email)).then(function (res) {
                var _a, _b;
                if (((_b = (_a = res === null || res === void 0 ? void 0 : res.payload) === null || _a === void 0 ? void 0 : _a.err) === null || _b === void 0 ? void 0 : _b.success) === false) {
                    setErr(res.payload.err.message);
                }
                if ((res === null || res === void 0 ? void 0 : res.payload) === 'email sent') {
                    navigate('/auth/resetting/check-email');
                }
            });
        }
        else {
            dispatch(SetState({ field: 'err', value: 'please enter email' }));
        }
    };
    return (_jsx("div", { className: 'main-content', children: _jsx(Container, { children: _jsx("div", { className: "login", id: "login", children: _jsx(Row, { className: "justify-content-center align-items-center", children: _jsx(Col, { md: 6, children: _jsx(Row, { className: "justify-content-center align-items-center full-height-viewport", children: _jsxs(Col, { md: 9, sm: 6, className: "text-center", children: [_jsx("div", { className: "security-logo", children: _jsx(Image, { src: logoUrl, alt: "Logo" }) }), _jsx("h1", { className: "login-title" }), _jsx("h5", { className: "text-start mb-3", children: "Forget Password" }), _jsxs(Form, { onSubmit: handleSubmit, className: "form-validation fos_user_resetting_request", children: [_jsx("div", { className: "field-section text-start", children: _jsx(Row, { children: _jsx(Col, { md: 12, children: _jsx("div", { className: "form-field", children: _jsx("div", { className: "input-group field-input", children: _jsx(Form.Control, { type: "email", id: "username", name: "username", required: true, placeholder: "Email", value: email, onChange: handleInputChange }) }) }) }) }) }), err && _jsx(Alert, { variant: "danger", className: "mt-3", children: err }), _jsxs("div", { className: "d-flex justify-content-between align-items-center mt-3", children: [_jsx(Button, { type: "submit", className: "btn btn-light-blue", children: "Send forget password link" }), _jsx(Link, { to: "/auth/signin", children: "Back to login" })] })] })] }) }) }) }) }) }) }));
};
export default ForgotPassword;

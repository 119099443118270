import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gettingBodyCorporatesByUser } from '../../../redux/reducers/dashboard';
import { Link } from 'react-router-dom';
var UserDashboard = function () {
    var _a;
    var bodyCorporates = useSelector(function (state) { return state.dashboard; }).bodyCorporates;
    var _b = useSelector(function (state) { return state.auth; }), firstname = _b.firstname, lastname = _b.lastname;
    var dispatch = useDispatch();
    useEffect(function () {
        dispatch(gettingBodyCorporatesByUser());
    }, []);
    return (_jsxs("div", { children: [_jsx("div", { className: "page-title-section", style: { paddingBottom: '20px' }, children: _jsx("div", { className: "container", children: _jsx("div", { className: "row", children: _jsx("div", { className: "col-md-12", children: _jsxs("h1", { children: ["Welcome ", firstname, " ", lastname] }) }) }) }) }), _jsx("div", { className: "container content-container", children: _jsx("div", { className: "row", children: _jsx("div", { className: "col-md-12", children: _jsxs("div", { className: "text-center", children: [_jsx("h4", { className: 'mb-4', children: "Select Body Corporate to view the dashboard" }), _jsx("div", { className: "row cl-flex cl-flex-center", children: _jsx("div", { className: "col-md-6", children: (_a = bodyCorporates === null || bodyCorporates === void 0 ? void 0 : bodyCorporates.rows) === null || _a === void 0 ? void 0 : _a.map(function (bodyCorporate) {
                                            return ((bodyCorporate === null || bodyCorporate === void 0 ? void 0 : bodyCorporate.isValid) === 1 && (_jsx(Link, { to: "/user/body-corporate/".concat(bodyCorporate === null || bodyCorporate === void 0 ? void 0 : bodyCorporate.name, "/").concat(bodyCorporate === null || bodyCorporate === void 0 ? void 0 : bodyCorporate.id), className: "btn btn-bc capitalize-text", children: bodyCorporate === null || bodyCorporate === void 0 ? void 0 : bodyCorporate.name }, bodyCorporate === null || bodyCorporate === void 0 ? void 0 : bodyCorporate.id)));
                                        }) }) })] }) }) }) })] }));
};
export default UserDashboard;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { useSortBy, usePagination, useTable } from 'react-table';
import PaginationComponent from '../../Common/Pagination';
import { Form } from 'react-bootstrap';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gettingcategories } from '../../../redux/reducers/dashboard';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
var Category = function () {
    var _a;
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var _b = useSelector(function (state) { return state.dashboard; }), err = _b.err, categories = _b.categories, loading = _b.loading;
    var _c = useState(''), searchTerm = _c[0], setSearchTerm = _c[1];
    var _d = useState(searchTerm), debouncedSearchTerm = _d[0], setDebouncedSearchTerm = _d[1];
    var _e = useState(0), pageIndexNo = _e[0], setPageIndexNo = _e[1];
    var _f = useState(20), pageSizeNo = _f[0], setPageSizeNo = _f[1];
    var _g = useState('name'), sortColumn = _g[0], setSortColumn = _g[1];
    var _h = useState('ASC'), sortOrder = _h[0], setSortOrder = _h[1];
    var _j = useState([]), flashMessages = _j[0], setFlashMessages = _j[1];
    var handleSearch = function (e) {
        e.preventDefault();
    };
    var data = useMemo(function () { return categories === null || categories === void 0 ? void 0 : categories.rows; }, [categories === null || categories === void 0 ? void 0 : categories.rows]);
    var columns = useMemo(function () { return [
        { Header: 'Category Name', id: 'name', accessor: 'name', width: '250px', },
        {
            Header: 'CreatedAt',
            accessor: 'createdAt',
            Cell: function (_a) {
                var value = _a.value;
                return format(new Date(value), 'MMM d, yyyy h:mm a');
            },
        },
        {
            Header: 'UpdatedAt',
            accessor: 'updatedAt',
            Cell: function (_a) {
                var value = _a.value;
                return format(new Date(value), 'MMM d, yyyy h:mm a');
            },
        },
        {
            Header: 'Actions',
            width: '80px',
            disableSortBy: true,
            Cell: function (_a) {
                var row = _a.row;
                return (_jsx("div", { className: "text-center text-xs-center", children: _jsx(Link, { to: "/staff/category/edit/".concat(row.original.id), className: "btn btn-blue", children: "Edit" }) }));
            },
        }
    ]; }, []);
    useEffect(function () {
        var handler = setTimeout(function () {
            setPageIndexNo(0);
            setDebouncedSearchTerm(searchTerm);
        }, 1000);
        return function () {
            clearTimeout(handler);
        };
    }, [searchTerm]);
    useEffect(function () {
        dispatch(gettingcategories({ page: pageIndexNo, pageSize: pageSizeNo, search: debouncedSearchTerm, sortColumn: sortColumn, sortOrder: sortOrder }));
    }, [pageIndexNo, pageSizeNo, debouncedSearchTerm, sortColumn, sortOrder]);
    var _k = useTable({
        columns: columns,
        data: data,
        manualSortBy: true,
        initialState: { pageIndex: 0, pageSize: 20, },
    }, useSortBy, usePagination), getTableProps = _k.getTableProps, getTableBodyProps = _k.getTableBodyProps, headerGroups = _k.headerGroups, prepareRow = _k.prepareRow, page = _k.page, canPreviousPage = _k.canPreviousPage, canNextPage = _k.canNextPage, pageOptions = _k.pageOptions, gotoPage = _k.gotoPage, nextPage = _k.nextPage, previousPage = _k.previousPage, setPageSize = _k.setPageSize, _l = _k.state, pageIndex = _l.pageIndex, pageSize = _l.pageSize, sortBy = _l.sortBy;
    var handleSort = function (columnId, canSort) {
        if (canSort) {
            setSortColumn(columnId);
            setSortOrder(function (prevSortOrder) { return (prevSortOrder === 'ASC' ? 'DESC' : 'ASC'); });
        }
    };
    return (_jsx("div", { className: "container content-container", children: _jsx("div", { className: "row", children: _jsxs("div", { className: "col-md-12", children: [flashMessages.map(function (flashMessage, index) { return (_jsx("div", { className: "cl-system-messages", children: _jsx("div", { className: "".concat(flashMessage.type, "-message"), children: flashMessage.message }) }, index)); }), _jsx("div", { className: "col-md-12 button-section text-xs-right text-md-end mb-3", children: _jsxs(Link, { to: "/staff/category/new", className: "btn btn-blue btn-lg", children: ["Add New ", _jsx(FontAwesomeIcon, { icon: faPlus })] }) }), _jsx("div", { className: "col-md-12 text-align-end", children: _jsx(Form, { onSubmit: handleSearch, className: "search-box", children: _jsxs(Form.Group, { controlId: "formSearch", className: 'd-flex justify-content-end align-items-center gap-2', children: [_jsx(Form.Label, { className: 'mb-0', children: "Search:" }), _jsx(Form.Control, { name: "search", type: "text", className: "form-control custom-width-20", value: searchTerm, onChange: function (e) { return setSearchTerm(e.target.value); } })] }) }) }), _jsxs("table", __assign({}, getTableProps(), { className: "data-table", children: [_jsx("thead", { children: headerGroups.map(function (headerGroup) { return (_jsx("tr", __assign({}, headerGroup.getHeaderGroupProps(), { children: headerGroup.headers.map(function (column) {
                                        var isSorted = sortColumn === column.id;
                                        var isSortedAsc = isSorted && sortOrder === 'ASC';
                                        var isSortedDesc = isSorted && sortOrder === 'DESC';
                                        return ((_jsxs("th", __assign({}, column.getHeaderProps({
                                            onClick: function () { return handleSort(column.id, column.canSort); },
                                        }), { style: { width: column === null || column === void 0 ? void 0 : column.width }, children: [column.render('Header'), column.canSort && (_jsx("span", { children: isSortedAsc ? ' 🔼' : isSortedDesc ? ' 🔽' : '' }))] }))));
                                    }) }))); }) }), _jsx("tbody", __assign({ className: 'dataTables_wrapper position-relative min-height-5rem' }, getTableBodyProps(), { children: loading ? (_jsx("div", { id: "contractor_datatable_processing", className: "dataTables_processing panel panel-default ".concat(loading ? 'd-block' : '', " "), children: "Processing..." })) : page.length === 0 ? (_jsx("tr", { children: _jsx("td", { colSpan: columns.length, className: 'text-center', children: "No matching records found" }) })) : (page.map(function (row) {
                                    prepareRow(row);
                                    return (_jsx("tr", __assign({}, row.getRowProps(), { children: row.cells.map(function (cell) { return (_jsx("td", __assign({}, cell.getCellProps(), { children: cell.render('Cell') }))); }) })));
                                })) }))] })), _jsx(PaginationComponent, { gotoPage: gotoPage, previousPage: previousPage, nextPage: nextPage, pageCount: Math.ceil((categories === null || categories === void 0 ? void 0 : categories.count) / pageSizeNo), canPreviousPage: canPreviousPage, canNextPage: canNextPage, pageIndex: pageIndexNo, pageOptions: pageOptions, pageSize: (_a = categories === null || categories === void 0 ? void 0 : categories.rows) === null || _a === void 0 ? void 0 : _a.length, fullCount: searchTerm ? categories === null || categories === void 0 ? void 0 : categories.fullCount : 0, setPageSize: setPageSize, totalCount: categories === null || categories === void 0 ? void 0 : categories.count, setPageIndexNo: setPageIndexNo })] }) }) }));
};
export default Category;

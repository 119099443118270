import { jsx as _jsx } from "react/jsx-runtime";
import Layout from '../../../layouts/dashboard';
import { useParams } from 'react-router-dom';
import InvoiceForm from './InvoiceForm';
import React, { useEffect } from 'react';
import InvoiceDetails from './InvoiceDetails';
import { useSelector } from 'react-redux';
import { gettingSingleNewInvoices } from '../../../redux/reducers/dashboard';
import { useDispatch } from 'react-redux';
var InvoiceWrapper = function (_a) {
    var Addnew = _a.Addnew, view = _a.view;
    var dispatch = useDispatch();
    var currentInvoice = useSelector(function (state) { return state.dashboard; }).currentInvoice;
    var id = useParams().id;
    useEffect(function () {
        if (id) {
            dispatch(gettingSingleNewInvoices(id));
        }
    }, []);
    return (_jsx(Layout, { title: "".concat(Addnew ? 'New Invoice' : 'Invoices'), InvoiceId: !Addnew ? "Invoice #".concat(currentInvoice === null || currentInvoice === void 0 ? void 0 : currentInvoice.invoiceNumber) : '', children: view ? (_jsx(InvoiceDetails, {})) : (_jsx(InvoiceForm, { Addnew: Addnew })) }));
};
export default InvoiceWrapper;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Form, FormControl, FormGroup, FormLabel, FormText } from 'react-bootstrap';
import { SetState, addNewInvoice, gettingBodyCorporateUsers, gettingBodyCorporates, gettingContractors, gettingFunds, gettingPreSignedUrl, gettingSingleNewInvoices, gettingcategories, updatingInvoice } from '../../../redux/reducers/dashboard';
import MessageBar from '../../Common/MessageBar';
import { useSelector } from 'react-redux';
import { generateFilename, getFileExtension, getFilePath } from '../../../Helpers/helper';
var REACT_APP_INVOICE_PDF_BUCKET_NAME_S3 = process.env.REACT_APP_INVOICE_PDF_BUCKET_NAME_S3;
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SpinnerComponent from '../../../pages/Common/SpinnerComponent';
var InvoiceForm = function (_a) {
    var Addnew = _a.Addnew;
    var dispatch = useDispatch();
    var location = useLocation();
    var navigate = useNavigate();
    var _b = useSelector(function (state) { return state.dashboard; }), err = _b.err, bodyCorporates = _b.bodyCorporates, contractors = _b.contractors, categories = _b.categories, funds = _b.funds, loading = _b.loading, currentInvoice = _b.currentInvoice, preSignedUrl = _b.preSignedUrl, invoiceLoading = _b.invoiceLoading;
    var id = useParams().id;
    var _c = useState('Change'), fileName = _c[0], setFileName = _c[1];
    var _d = useState(''), filepathText = _d[0], setFilepathText = _d[1];
    var _e = useState(false), uploadingfileLoading = _e[0], setUploadingfileLoading = _e[1];
    var _f = useState(null), startDate = _f[0], setStartDate = _f[1];
    var _g = useState(null), selectedFile = _g[0], setSelectedFile = _g[1];
    var _h = useState(false), isDataLoaded = _h[0], setIsDataLoaded = _h[1];
    var _j = useState([]), defaultSelectedFund = _j[0], setDefaultSelectedFund = _j[1];
    var _k = useState([]), defaultSelectedCategory = _k[0], setDefaultSelectedCategory = _k[1];
    var _l = useState([]), defaultSelectedContractor = _l[0], setDefaultSelectedContractor = _l[1];
    var _m = useState([]), defaultSelectedBodyCorporate = _m[0], setDefaultSelectedBodyCorporate = _m[1];
    var _o = useState(''), submitType = _o[0], setSubmitType = _o[1];
    var _p = useState(false), showBar = _p[0], setShowBar = _p[1];
    var _q = useState({
        type: '',
        message: '',
    }), messageBarProps = _q[0], setMessageBarProps = _q[1];
    var _r = useForm(), register = _r.register, handleSubmit = _r.handleSubmit, reset = _r.reset, setValue = _r.setValue, errors = _r.formState.errors;
    var fetchData = useCallback(function () {
        Promise.all([
            dispatch(gettingContractors({ page: -1, pageSize: -1, search: '', sortColumn: "companyName", sortOrder: "ASC" })),
            dispatch(gettingcategories({ page: -1, pageSize: -1, search: '', sortColumn: "name", sortOrder: "ASC" })),
            dispatch(gettingFunds({ page: -1, pageSize: -1, search: '', sortColumn: "name", sortOrder: "ASC" })),
            dispatch(gettingBodyCorporates({ page: -1, pageSize: -1, search: '', sortColumn: "name", sortOrder: "ASC" })),
            dispatch(gettingBodyCorporateUsers({ page: -1, pageSize: -1, search: '', sortColumn: "firstname", sortOrder: "ASC" }))
        ]).then(function () { return setIsDataLoaded(true); });
    }, [dispatch]);
    var handleDateChange = function (date) {
        setStartDate(date);
        setValue('dueDate', date);
    };
    useEffect(function () {
        fetchData();
        if (id) {
            dispatch(gettingSingleNewInvoices(id));
        }
    }, [fetchData]);
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        if (!isDataLoaded)
            return;
        if (currentInvoice) {
            setFilepathText(currentInvoice === null || currentInvoice === void 0 ? void 0 : currentInvoice.filepath);
            setStartDate((currentInvoice === null || currentInvoice === void 0 ? void 0 : currentInvoice.dueDate)
                ? new Date(currentInvoice === null || currentInvoice === void 0 ? void 0 : currentInvoice.dueDate)
                : null);
            reset({
                dueDate: (currentInvoice === null || currentInvoice === void 0 ? void 0 : currentInvoice.dueDate)
                    ? new Date(currentInvoice === null || currentInvoice === void 0 ? void 0 : currentInvoice.dueDate)
                    : null,
                invoiceNumber: (_a = currentInvoice === null || currentInvoice === void 0 ? void 0 : currentInvoice.invoiceNumber) === null || _a === void 0 ? void 0 : _a.toString(),
                amount: (_b = currentInvoice === null || currentInvoice === void 0 ? void 0 : currentInvoice.amount) === null || _b === void 0 ? void 0 : _b.toString(),
                contractorId: (_c = currentInvoice === null || currentInvoice === void 0 ? void 0 : currentInvoice.contractorId) === null || _c === void 0 ? void 0 : _c.toString(),
                fundId: (_d = currentInvoice === null || currentInvoice === void 0 ? void 0 : currentInvoice.fundId) === null || _d === void 0 ? void 0 : _d.toString(),
                categoryId: (_e = currentInvoice === null || currentInvoice === void 0 ? void 0 : currentInvoice.categoryId) === null || _e === void 0 ? void 0 : _e.toString(),
                bodyCorporateId: (_f = currentInvoice === null || currentInvoice === void 0 ? void 0 : currentInvoice.bodyCorporateId) === null || _f === void 0 ? void 0 : _f.toString()
            });
            var matchedContractor = (_g = contractors === null || contractors === void 0 ? void 0 : contractors.rows) === null || _g === void 0 ? void 0 : _g.find(function (row) { return (row === null || row === void 0 ? void 0 : row.id) === (currentInvoice === null || currentInvoice === void 0 ? void 0 : currentInvoice.contractorId); });
            if (matchedContractor) {
                setDefaultSelectedContractor([matchedContractor]);
            }
            var matchedCategory = (_h = categories === null || categories === void 0 ? void 0 : categories.rows) === null || _h === void 0 ? void 0 : _h.find(function (row) { return (row === null || row === void 0 ? void 0 : row.id) === (currentInvoice === null || currentInvoice === void 0 ? void 0 : currentInvoice.categoryId); });
            if (matchedCategory) {
                setDefaultSelectedCategory([matchedCategory]);
            }
            var matchedBodyCorporate = (_j = bodyCorporates === null || bodyCorporates === void 0 ? void 0 : bodyCorporates.rows) === null || _j === void 0 ? void 0 : _j.find(function (row) { return (row === null || row === void 0 ? void 0 : row.id) === (currentInvoice === null || currentInvoice === void 0 ? void 0 : currentInvoice.bodyCorporateId); });
            if (matchedBodyCorporate) {
                setDefaultSelectedBodyCorporate([matchedBodyCorporate]);
            }
            var matchedFund = (_k = funds === null || funds === void 0 ? void 0 : funds.rows) === null || _k === void 0 ? void 0 : _k.find(function (row) { return (row === null || row === void 0 ? void 0 : row.id) === (currentInvoice === null || currentInvoice === void 0 ? void 0 : currentInvoice.fundId); });
            if (matchedFund) {
                setDefaultSelectedFund([matchedFund]);
            }
        }
    }, [location, currentInvoice, reset, contractors, categories, bodyCorporates, funds, isDataLoaded]);
    var uploadFile = function (file, presignedUrl) { return __awaiter(void 0, void 0, void 0, function () {
        var response, path, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, fetch("".concat(presignedUrl), {
                            method: "PUT",
                            body: file,
                            headers: {
                                "Content-Type": file === null || file === void 0 ? void 0 : file.type,
                            },
                        })];
                case 1:
                    response = _a.sent();
                    if (!response.ok) {
                        throw new Error("Upload failed with status: ".concat(response.status));
                    }
                    dispatch(SetState({ field: "preSignedUrl", value: "" }));
                    path = getFilePath(response === null || response === void 0 ? void 0 : response.url);
                    return [2 /*return*/, path];
                case 2:
                    err_1 = _a.sent();
                    console.error("Error uploading File:", err_1);
                    throw err_1;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var path;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    path = (currentInvoice === null || currentInvoice === void 0 ? void 0 : currentInvoice.filepath) || '';
                    if (!selectedFile) return [3 /*break*/, 2];
                    setUploadingfileLoading(true);
                    return [4 /*yield*/, uploadFile(selectedFile, preSignedUrl)];
                case 1:
                    path = _a.sent();
                    setUploadingfileLoading(false);
                    _a.label = 2;
                case 2:
                    if (Addnew) {
                        dispatch(addNewInvoice(__assign(__assign({}, data), { filepath: path }))).then(function (res) {
                            var _a, _b;
                            var success = (res === null || res === void 0 ? void 0 : res.payload).success;
                            setShowBar(true);
                            if (success === true) {
                                setMessageBarProps({
                                    type: "success",
                                    message: "Invoice add successfully!"
                                });
                                reset();
                                setStartDate(null);
                                setDefaultSelectedContractor([]);
                                setDefaultSelectedCategory([]);
                                setDefaultSelectedBodyCorporate([]);
                                setDefaultSelectedFund([]);
                                setSelectedFile(null);
                                setTimeout(function () {
                                    navigate('/staff/invoice');
                                }, 1000);
                            }
                            else {
                                setMessageBarProps({
                                    type: "error",
                                    message: (_b = (_a = res === null || res === void 0 ? void 0 : res.payload) === null || _a === void 0 ? void 0 : _a.err) === null || _b === void 0 ? void 0 : _b.message
                                });
                            }
                        });
                    }
                    else {
                        dispatch(updatingInvoice(__assign(__assign({}, data), { filepath: path, invoiceId: currentInvoice === null || currentInvoice === void 0 ? void 0 : currentInvoice.id }))).then(function (res) {
                            var _a, _b;
                            var success = (res === null || res === void 0 ? void 0 : res.payload).success;
                            setShowBar(true);
                            if (success === true) {
                                setMessageBarProps({
                                    type: "success",
                                    message: "Invoice updated successfully!"
                                });
                                setSelectedFile(null);
                                setTimeout(function () {
                                    navigate('/staff/invoice');
                                }, 1000);
                            }
                            else {
                                setMessageBarProps({
                                    type: "error",
                                    message: (_b = (_a = res === null || res === void 0 ? void 0 : res.payload) === null || _a === void 0 ? void 0 : _a.err) === null || _b === void 0 ? void 0 : _b.message
                                });
                            }
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handleFileChange = function (event) {
        var _a, _b;
        var file = (_b = (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b[0];
        if (file) {
            var fileExt = getFileExtension(file.name);
            setFileName(file.name);
            setSelectedFile(file);
            var fileName_1 = generateFilename(fileExt);
            dispatch(gettingPreSignedUrl({ fileName: fileName_1, bucketName: REACT_APP_INVOICE_PDF_BUCKET_NAME_S3 }));
        }
        else {
            setSelectedFile(null);
            setFileName('Choose File');
        }
    };
    return (uploadingfileLoading || invoiceLoading ?
        (_jsx(SpinnerComponent, {})) : (_jsx(Container, { className: 'content-container', children: _jsxs("div", { className: "row", children: [showBar && (_jsx(MessageBar, { type: messageBarProps.type, message: messageBarProps.message })), _jsxs("div", { className: "col-md-4", children: [_jsxs(Link, { to: "/staff/invoice", className: "btn btn-red mb-3", children: [_jsx(FontAwesomeIcon, { icon: faArrowLeft }), " Back"] }), _jsxs(Form, { onSubmit: handleSubmit(onSubmit), children: [_jsxs(FormGroup, { className: "mb-3", children: [_jsx(FormLabel, { children: "Contractor" }), _jsx(Typeahead, __assign({ id: "contractorId" }, register('contractorId', { required: true }), { labelKey: "companyName", filterBy: ['companyName'], selected: defaultSelectedContractor, onFocus: function () {
                                                setDefaultSelectedContractor([]);
                                                setValue('contractorId', '');
                                            }, onChange: function (selected) {
                                                if (selected.length > 0) {
                                                    var selectedContractor = selected[0];
                                                    setDefaultSelectedContractor([selectedContractor]);
                                                    setValue('contractorId', selectedContractor.id.toString());
                                                }
                                                else {
                                                    setDefaultSelectedContractor([]);
                                                    setValue('contractorId', '');
                                                }
                                            }, options: contractors.rows, placeholder: "Select Contractor" })), errors.contractorId && _jsx(FormText, { className: "text-danger", children: "This field is required" })] }), _jsxs(FormGroup, { className: "mb-3", children: [_jsx(FormLabel, { children: "Invoice Number" }), _jsx(FormControl, __assign({}, register('invoiceNumber', { required: true }), { isInvalid: !!errors.invoiceNumber })), errors.invoiceNumber && _jsx(FormText, { className: "text-danger", children: "This field is required" })] }), _jsxs(FormGroup, { className: "mb-3", children: [_jsx(FormLabel, { children: "Amount($)" }), _jsx(FormControl, __assign({}, register('amount', { required: true }), { isInvalid: !!errors.amount })), errors.amount && _jsx(FormText, { className: "text-danger", children: "This field is required" })] }), _jsxs(FormGroup, { className: "mb-3 d-flex flex-column", children: [_jsx(FormLabel, { children: "Due Date" }), _jsx(DatePicker, __assign({}, register('dueDate', { required: true }), { selected: startDate, onChange: handleDateChange, className: "form-control ".concat(errors.dueDate ? 'is-invalid' : ''), dateFormat: "dd-MM-yyyy" })), errors.dueDate && (_jsx(FormText, { className: "text-danger", children: "This field is required" }))] }), _jsxs(FormGroup, { className: "mb-3", children: [_jsx(FormLabel, { children: "Fund" }), _jsx(Typeahead, __assign({ id: "fundId" }, register('fundId', { required: true }), { labelKey: "name", filterBy: ['name'], selected: defaultSelectedFund, onFocus: function () {
                                                setDefaultSelectedFund([]);
                                                setValue('fundId', '');
                                            }, onChange: function (selected) {
                                                if (selected.length > 0) {
                                                    var selectedFund = selected[0];
                                                    setDefaultSelectedFund([selectedFund]);
                                                    setValue('fundId', selectedFund.id.toString());
                                                }
                                                else {
                                                    setDefaultSelectedFund([]);
                                                    setValue('fundId', '');
                                                }
                                            }, options: funds.rows, placeholder: "Select Funds" })), errors.fundId && _jsx(FormText, { className: "text-danger", children: "This field is required" })] }), _jsxs(FormGroup, { className: "mb-3", children: [_jsx(FormLabel, { children: "Category" }), _jsx(Typeahead, __assign({ id: "categoryId" }, register('categoryId', { required: true }), { labelKey: "name", filterBy: ['name'], selected: defaultSelectedCategory, onFocus: function () {
                                                setDefaultSelectedCategory([]);
                                                setValue('categoryId', '');
                                            }, onChange: function (selected) {
                                                if (selected.length > 0) {
                                                    var selectedCategory = selected[0];
                                                    setDefaultSelectedCategory([selectedCategory]);
                                                    setValue('categoryId', selectedCategory.id.toString());
                                                }
                                                else {
                                                    setDefaultSelectedCategory([]);
                                                    setValue('categoryId', '');
                                                }
                                            }, options: categories.rows, placeholder: "Select Category" })), errors.categoryId && _jsx(FormText, { className: "text-danger", children: "This field is required" })] }), _jsxs(FormGroup, { className: "mb-3", children: [_jsx(FormLabel, { children: "Body Corporate" }), _jsx(Typeahead, __assign({ id: "bodyCorporateId" }, register('bodyCorporateId', { required: true }), { labelKey: "name", filterBy: ['name'], selected: defaultSelectedBodyCorporate, onFocus: function () {
                                                setDefaultSelectedBodyCorporate([]);
                                                setValue('bodyCorporateId', '');
                                            }, onChange: function (selected) {
                                                if (selected.length > 0) {
                                                    var selectedBodyCorporate = selected[0];
                                                    setDefaultSelectedBodyCorporate([selectedBodyCorporate]);
                                                    setValue('bodyCorporateId', selectedBodyCorporate.id.toString());
                                                }
                                                else {
                                                    setDefaultSelectedBodyCorporate([]);
                                                    setValue('bodyCorporateId', '');
                                                }
                                            }, options: bodyCorporates.rows, placeholder: "Select Body Corporate" })), errors.bodyCorporateId && _jsx(FormText, { className: "text-danger", children: "This field is required" })] }), _jsxs(Form.Group, { className: 'mb-3 d-flex flex-column align-items-start', children: [_jsx(Form.Label, { children: "Upload File" }), Addnew ? (_jsx(FormControl, __assign({ type: "file" }, register('filepath', { required: true }), { isInvalid: !!errors.filepath, placeholder: "Choose a file pdf", onChange: handleFileChange }))) : (_jsxs(_Fragment, { children: [_jsx(FormControl, __assign({ type: "file" }, register('filepath'), { isInvalid: !!errors.filepath, style: { display: 'none' }, id: "fileUpload", onChange: handleFileChange })), _jsx("label", { htmlFor: "fileUpload", className: "btn btn-blue", children: fileName })] })), errors.filepath && _jsx(FormText, { className: "text-danger", children: "This field is required" })] }), _jsx(FormGroup, { className: 'mb-3', children: _jsx(Form.Text, { children: filepathText }) }), _jsx("div", { className: "cl-flex cl-flex-middle justify-content-between between-xs", children: _jsx("input", { type: "submit", name: "submit", className: "btn btn-blue pull-left", value: Addnew ? 'Create' : 'Update', onClick: function () { return setSubmitType('create'); } }) })] })] })] }) })));
};
export default InvoiceForm;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, } from 'react-router-dom';
import { getDetails, resetPassword } from '../../redux/reducers/auth';
var logo = require('../../assets/images/logo.png');
var logoUrl = logo.default;
var ResetPassword = function () {
    var location = useLocation();
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var query = new URLSearchParams(location === null || location === void 0 ? void 0 : location.search);
    var token = query === null || query === void 0 ? void 0 : query.get('token');
    var _a = useForm(), handleSubmit = _a.handleSubmit, control = _a.control;
    var _b = useState({}), formErrors = _b[0], setFormErrors = _b[1];
    var onSubmit = function (data) {
        var errors = {};
        if (!data.plainPasswordFirst) {
            errors.plainPasswordFirst = 'New Password is required';
        }
        if (!data.plainPasswordSecond) {
            errors.plainPasswordSecond = 'Repeat New Password is required';
        }
        else if (data.plainPasswordFirst !== data.plainPasswordSecond) {
            errors.plainPasswordSecond = 'Passwords must match';
        }
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
        }
        else {
            setFormErrors({});
            dispatch(resetPassword({ password: data === null || data === void 0 ? void 0 : data.plainPasswordFirst, token: token })).then(function (res) {
                var _a;
                if (((_a = res === null || res === void 0 ? void 0 : res.payload) === null || _a === void 0 ? void 0 : _a.success) === true) {
                    dispatch(getDetails(token)).then(function (res) {
                        var user = (res === null || res === void 0 ? void 0 : res.payload).user;
                        if (user === null || user === void 0 ? void 0 : user.email) {
                            navigate('/auth/signin');
                        }
                    });
                }
            });
        }
    };
    var backToLogin = function () {
        dispatch(getDetails(token)).then(function (res) {
            var user = (res === null || res === void 0 ? void 0 : res.payload).user;
            if (user === null || user === void 0 ? void 0 : user.email) {
                navigate('/auth/signin');
            }
        });
    };
    return (_jsx("div", { className: 'main-content', children: _jsx(Container, { children: _jsx("div", { className: "login", id: "login", children: _jsx(Row, { className: "justify-content-center align-items-center", children: _jsx(Col, { md: 6, children: _jsx(Row, { className: "justify-content-center align-items-center full-height-viewport", children: _jsxs(Col, { md: 9, sm: 6, className: "text-center", children: [_jsx("div", { className: "security-logo", children: _jsx(Image, { src: logoUrl, alt: "Logo" }) }), _jsx("h1", { className: "login-title" }), _jsx("h5", { className: "text-start mb-3", children: "Reset Password" }), _jsx("form", { onSubmit: handleSubmit(onSubmit), children: _jsx("div", { className: "field-section text-xs-left", children: _jsxs("div", { className: "row", children: [_jsx("div", { className: "col-md-12", children: _jsx("div", { className: "form-field", children: _jsxs("div", { className: "input-group field-input", children: [_jsx(Controller, { name: "plainPasswordFirst", control: control, defaultValue: "", render: function (_a) {
                                                                            var field = _a.field;
                                                                            return (_jsx("input", __assign({}, field, { type: "password", className: "form-control required ".concat(formErrors.plainPasswordFirst ? 'is-invalid' : ''), placeholder: "New Password" })));
                                                                        } }), formErrors.plainPasswordFirst && (_jsx("div", { className: "invalid-feedback", children: formErrors.plainPasswordFirst }))] }) }) }), _jsx("div", { className: "col-md-12", children: _jsx("div", { className: "form-field", children: _jsxs("div", { className: "input-group field-input", children: [_jsx(Controller, { name: "plainPasswordSecond", control: control, defaultValue: "", render: function (_a) {
                                                                            var field = _a.field;
                                                                            return (_jsx("input", __assign({}, field, { type: "password", className: "form-control required ".concat(formErrors.plainPasswordSecond ? 'is-invalid' : ''), placeholder: "Repeat New Password" })));
                                                                        } }), formErrors.plainPasswordSecond && (_jsx("div", { className: "invalid-feedback", children: formErrors.plainPasswordSecond }))] }) }) }), _jsxs("div", { className: "d-flex justify-content-between align-items-center mt-3", children: [_jsx(Button, { type: "submit", className: "btn btn-light-blue", children: "CHange Password" }), _jsx("a", { role: 'button', onClick: backToLogin, children: "Back to login" })] })] }) }) })] }) }) }) }) }) }) }));
};
export default ResetPassword;

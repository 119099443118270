import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SignUp from '../pages/auth/signUp';
import ForgetPassword from '../pages/auth/forgetPassword';
import PageNotFound from '../pages/PageNotFound';
import Login from '../pages/auth/Login';
import ResetPassword from '../pages/auth/resetPassword';
import CheckEmail from '../pages/auth/checkEmail';
var AuthRoute = function () {
    return (_jsxs(Routes, { children: [_jsx(Route, { path: "signin", element: _jsx(Login, {}) }), _jsx(Route, { path: "signup", element: _jsx(SignUp, {}) }), _jsx(Route, { path: "forgetpassword", element: _jsx(ForgetPassword, {}) }), _jsx(Route, { path: "resetting/reset", element: _jsx(ResetPassword, {}) }), _jsx(Route, { path: "resetting/check-email", element: _jsx(CheckEmail, {}) }), _jsx(Route, { path: "*", element: _jsx(PageNotFound, {}) })] }));
};
export default AuthRoute;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSelector } from 'react-redux';
var NotificationList = function () {
    var notificationClass = { 1: 'info', 2: 'success', 3: 'danger' };
    var _a = useSelector(function (state) { return state.dashboard; }).notifications, rows = _a.rows, count = _a.count;
    return (_jsx("div", { className: "container content-container", children: _jsx("div", { className: "row", children: _jsx("div", { className: "col-md-12", children: _jsx("div", { className: "allNotificationList", children: _jsx("div", { className: "row", children: _jsx("div", { className: "col-md-12", children: rows === null || rows === void 0 ? void 0 : rows.map(function (notification, index) { return (_jsx("div", { className: "notify-item", children: _jsxs("div", { className: "cl-flex cl-flex-middle", children: [_jsx("a", { href: notification === null || notification === void 0 ? void 0 : notification.url, className: "notify-icon notify-".concat(notificationClass[notification === null || notification === void 0 ? void 0 : notification.type]), children: _jsx(FontAwesomeIcon, { icon: faBell }) }), _jsx("div", { children: (notification === null || notification === void 0 ? void 0 : notification.url) === '' ? (_jsx("a", { href: "#", children: notification === null || notification === void 0 ? void 0 : notification.msg })) : (_jsx("a", { href: notification === null || notification === void 0 ? void 0 : notification.url, children: notification === null || notification === void 0 ? void 0 : notification.msg })) })] }) }, index)); }) }) }) }) }) }) }));
};
export default NotificationList;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
var Navbar = function () {
    var location = useLocation();
    var _a = useState(''), currentRoute = _a[0], setCurrentRoute = _a[1];
    var roles = useSelector(function (state) { return state.auth; }).roles;
    var navlist = [];
    if (roles === "ROLE_BC_USER") {
        navlist = [
            { name: 'Dashboard', to: '/user' },
            { name: 'Reports', to: '/user/reports' },
        ];
    }
    else {
        navlist = [
            { name: 'Dashboard', to: '/staff' },
            { name: 'Staff', to: '/staff/staffs' },
            { name: 'Invoices', to: '/staff/invoice' },
            { name: 'Contractors', to: '/staff/contractor' },
            { name: 'Body Corporate', to: '/staff/body-corporate' },
            { name: 'Body Corporate Users', to: '/staff/body-corporate-users' },
            { name: 'Reports', to: '/staff/reports' },
        ];
    }
    useEffect(function () {
        setCurrentRoute(location.pathname);
    }, [location.pathname]);
    var isActive = function (navItemTo) {
        if (location.pathname === navItemTo) {
            return true;
        }
        if (navItemTo !== '/staff' && location.pathname.startsWith(navItemTo + '/') && (navItemTo !== '/user' && location.pathname.startsWith(navItemTo + '/'))) {
            return true;
        }
        return false;
    };
    return (_jsx("div", { className: "main-menu", children: _jsx("div", { className: "container", children: _jsx("div", { className: "row", children: _jsx("div", { className: "col-md-12", children: _jsxs("nav", { className: "navbar navbar-toggleable-md navbar-light bg-faded", children: [_jsx("button", { className: "navbar-toggler navbar-toggler-right", type: "button", "data-toggle": "collapse", "data-target": "#navbarSupportedContent", "aria-controls": "navbarSupportedContent", "aria-expanded": "false", "aria-label": "Toggle navigation", children: _jsx("span", { className: "navbar-toggler-icon" }) }), _jsx("a", { className: "navbar-brand", href: "#", children: "Menu" }), _jsxs("div", { className: "collapse navbar-collapse popover-tooltip", id: "navbarSupportedContent", children: [_jsx("ul", { className: "navbar-nav mr-auto", children: navlist.map(function (nav, index) { return (_jsx("li", { className: "nav-item ".concat(isActive(nav.to) ? 'active' : ''), children: _jsx(Link, { className: "nav-link", to: nav.to, children: nav.name }) }, index)); }) }), _jsx("div", { className: "clearBoth" })] })] }) }) }) }) }));
};
export default Navbar;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { faBarChart } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTable } from 'react-table';
var ReportInvoiceTable = function (_a) {
    var invoices = _a.invoices;
    var grandTotal = useMemo(function () {
        return invoices === null || invoices === void 0 ? void 0 : invoices.reduce(function (acc, invoice) { return acc + parseFloat((invoice === null || invoice === void 0 ? void 0 : invoice.amount) || '0'); }, 0).toFixed(2);
    }, [invoices]);
    var grandInvoices = useMemo(function () {
        return invoices === null || invoices === void 0 ? void 0 : invoices.length;
    }, [invoices]);
    var columns = React.useMemo(function () { return [
        {
            Header: 'DATE ENTERED',
            accessor: 'createdAt',
            Cell: function (_a) {
                var _b;
                var value = _a.value;
                return (value ? (_b = new Date(value)) === null || _b === void 0 ? void 0 : _b.toLocaleDateString() : 'Not Available');
            },
        },
        {
            Header: 'STATUS',
            accessor: 'status',
            Cell: function (_a) {
                var value = _a.value;
                return (value === 2 ? 'Accepted' : value === 3 ? 'Declined' : 'Pending');
            },
        },
        {
            Header: 'OUTCOME DATE',
            accessor: 'outcome',
            Cell: function (_a) {
                var value = _a.value;
                return ((value === null || value === void 0 ? void 0 : value.date) ? new Date(value === null || value === void 0 ? void 0 : value.date).toLocaleDateString() : value === null || value === void 0 ? void 0 : value.status);
            },
        },
        {
            Header: 'TRANSACTIONS',
            accessor: 'transactions',
            width: '400px',
            Cell: function (_a) {
                var value = _a.value;
                return (value === null || value === void 0 ? void 0 : value.length) > 0
                    ? value === null || value === void 0 ? void 0 : value.map(function (transaction, index) { return (_jsxs("p", { children: [(transaction === null || transaction === void 0 ? void 0 : transaction.actionDate) ? new Date(transaction === null || transaction === void 0 ? void 0 : transaction.actionDate).toLocaleDateString() : 'Date Not Available', ": ", transaction === null || transaction === void 0 ? void 0 : transaction.userName, " - ", transaction === null || transaction === void 0 ? void 0 : transaction.action] }, index)); })
                    : 'Pending';
            },
        },
        {
            Header: 'CONTRACTOR NAME',
            accessor: 'contractor',
            Cell: function (_a) {
                var value = _a.value;
                return value ? value === null || value === void 0 ? void 0 : value.companyName : 'Not Available';
            }
        },
        {
            Header: 'INVOICE NUMBER',
            accessor: 'invoiceNumber',
            Cell: function (_a) {
                var _b, _c;
                var row = _a.row;
                var invoiceId = (_b = row === null || row === void 0 ? void 0 : row.original) === null || _b === void 0 ? void 0 : _b.id;
                var invoiceNumber = (_c = row === null || row === void 0 ? void 0 : row.original) === null || _c === void 0 ? void 0 : _c.invoiceNumber;
                return _jsx(Link, { style: { color: '#212545' }, role: 'button', to: "/staff/invoice/view/".concat(invoiceId), target: "_blank", children: invoiceNumber });
            },
        },
        {
            Header: 'CATEGORY',
            accessor: 'category',
            Cell: function (_a) {
                var value = _a.value;
                return value ? value === null || value === void 0 ? void 0 : value.name : 'Not Available';
            }
        },
        {
            Header: 'FUND',
            accessor: 'fund',
            Cell: function (_a) {
                var value = _a.value;
                return value ? value === null || value === void 0 ? void 0 : value.name : 'Not Available';
            }
        },
        {
            Header: 'AMOUNT',
            accessor: 'amount',
            Cell: function (_a) {
                var value = _a.value;
                return "$".concat(parseFloat(value).toFixed(2));
            },
        },
        {
            Header: 'BODY CORPORATE',
            accessor: 'bodyCorporate',
            Cell: function (_a) {
                var value = _a.value;
                return value ? value === null || value === void 0 ? void 0 : value.name : 'Not Available';
            }
        },
    ]; }, []);
    var data = React.useMemo(function () { return invoices; }, [invoices]);
    var _b = useTable({ columns: columns, data: data }), getTableProps = _b.getTableProps, getTableBodyProps = _b.getTableBodyProps, headerGroups = _b.headerGroups, rows = _b.rows, prepareRow = _b.prepareRow;
    return (_jsx("div", { className: "portlet", children: _jsxs("div", { className: "table-container", children: [_jsxs("table", __assign({}, getTableProps(), { className: "table table-striped table-bordered table-hover", children: [_jsx("thead", { className: 'report-header-font', children: headerGroups.map(function (headerGroup) { return (_jsx("tr", __assign({}, headerGroup.getHeaderGroupProps(), { children: headerGroup.headers.map(function (column) { return (_jsx("th", __assign({}, column.getHeaderProps(), { style: { width: column === null || column === void 0 ? void 0 : column.width }, children: column.render('Header') }))); }) }))); }) }), _jsx("tbody", __assign({}, getTableBodyProps(), { className: 'report-header-font', children: rows.map(function (row) {
                                prepareRow(row);
                                return (_jsx("tr", __assign({}, row.getRowProps(), { children: row.cells.map(function (cell) { return (_jsx("td", __assign({}, cell.getCellProps(), { children: cell.render('Cell') }))); }) })));
                            }) }))] })), _jsxs("div", { className: "summary my-4", style: { float: 'right', border: '1px solid gray', padding: '10px' }, children: [_jsxs("h4", { children: [_jsx(FontAwesomeIcon, { icon: faBarChart, size: "1x" }), " Summary"] }), _jsxs("p", { className: 'mb-1', children: ["Invoices: ", grandInvoices] }), _jsxs("p", { className: 'mb-1', children: ["Total Amount: $", grandTotal] })] })] }) }));
};
export default ReportInvoiceTable;

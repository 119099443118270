import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
var RoleBasedRedirect = function () {
    var roles = useSelector(function (state) { return state.auth; }).roles;
    if (roles === null || roles === void 0 ? void 0 : roles.includes("ROLE_STAFF")) {
        return _jsx(Navigate, { to: "/staff" });
    }
    else if (roles === null || roles === void 0 ? void 0 : roles.includes("ROLE_BC_USER")) {
        return _jsx(Navigate, { to: "/user" });
    }
    else {
        return _jsx(Navigate, { to: "/auth/signin" });
    }
};
export default RoleBasedRedirect;

import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import RoleBasedRedirect from './RoleBasedRedirect';
var PublicRoute = function () {
    var token = useSelector(function (state) { return state.auth; }).token;
    return token === '' ? _jsx(Outlet, {}) : _jsx(RoleBasedRedirect, {});
};
export default PublicRoute;

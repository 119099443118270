import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Pagination } from 'react-bootstrap';
var PaginationComponent = function (_a) {
    var gotoPage = _a.gotoPage, previousPage = _a.previousPage, nextPage = _a.nextPage, pageCount = _a.pageCount, canPreviousPage = _a.canPreviousPage, canNextPage = _a.canNextPage, pageIndex = _a.pageIndex, pageOptions = _a.pageOptions, pageSize = _a.pageSize, totalCount = _a.totalCount, _b = _a.fullCount, fullCount = _b === void 0 ? 0 : _b, setPageSize = _a.setPageSize, setPageIndexNo = _a.setPageIndexNo;
    var renderPaginationItems = function () {
        var items = [];
        if (pageCount <= 2) {
            items.push(_jsx(Pagination.Item, { className: 'pagination-item', active: 0 === pageIndex, onClick: function () {
                    gotoPage(0);
                    setPageIndexNo(0);
                }, children: "1" }, 0));
            if (pageCount === 2) {
                items.push(_jsx(Pagination.Item, { className: 'pagination-item', active: 1 === pageIndex, onClick: function () {
                        gotoPage(1);
                        setPageIndexNo(1);
                    }, children: "2" }, 1));
            }
        }
        else {
            items.push(_jsx(Pagination.Item, { className: 'pagination-item', active: 0 === pageIndex, onClick: function () {
                    gotoPage(0);
                    setPageIndexNo(0);
                }, children: "First" }, 0));
            var visiblePages = 5;
            var leftEllipsis = pageIndex > 2;
            var rightEllipsis = pageIndex < pageCount - 3;
            var _loop_1 = function (i) {
                if (i === pageIndex) {
                    items.push(_jsx(Pagination.Item, { active: true, className: 'pagination-item', onClick: function () {
                            gotoPage(i);
                            setPageIndexNo(i);
                        }, children: i + 1 }, i));
                }
                else if (i >= pageIndex - 2 && i <= pageIndex + 2) {
                    items.push(_jsx(Pagination.Item, { className: 'pagination-item', onClick: function () {
                            gotoPage(i);
                            setPageIndexNo(i);
                        }, children: i + 1 }, i));
                }
                else if ((i === 1 && leftEllipsis) || (i === pageCount - 2 && rightEllipsis)) {
                    items.push(_jsx(Pagination.Ellipsis, {}, i));
                }
            };
            for (var i = 1; i < pageCount - 1; i++) {
                _loop_1(i);
            }
            items.push(_jsx(Pagination.Item, { className: 'pagination-item', active: pageCount - 1 === pageIndex, onClick: function () {
                    gotoPage(pageCount - 1);
                    setPageIndexNo(pageCount - 1);
                }, children: "Last" }, pageCount - 1));
        }
        return items;
    };
    return (_jsxs("div", { className: "row justify-content-center align-items-center ps-3 pe-3", children: [_jsx("div", { className: "col-sm-5 d-flex justify-content-start", children: _jsx("div", { className: "mt-3", children: _jsxs("span", { children: ["Showing", ' ', _jsxs("strong", { children: [pageSize, " of ", totalCount] }), ' ', "entries", ' ', fullCount ? (_jsxs(_Fragment, { children: [' (filtered from ', _jsx("strong", { children: fullCount }), ' total entries)'] })) : ('')] }) }) }), _jsx("div", { className: "col-sm-7 d-flex justify-content-center", children: _jsx("div", { className: 'dataTables_paginate paging_simple_numbers', children: _jsx("nav", { className: "pagination", children: _jsxs(Pagination, { children: [_jsx(Pagination.Prev, { onClick: function () {
                                        if (pageIndex > 0) {
                                            previousPage();
                                            setPageIndexNo(pageIndex - 1);
                                        }
                                    }, disabled: pageIndex === 0, children: "Previous" }), renderPaginationItems(), _jsx(Pagination.Next, { onClick: function () {
                                        if (pageIndex < pageCount - 1) {
                                            nextPage();
                                            setPageIndexNo(pageIndex + 1);
                                        }
                                    }, disabled: pageIndex === pageCount - 1, children: "Next" })] }) }) }) })] }));
};
export default PaginationComponent;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTable, useSortBy, usePagination } from 'react-table';
import PaginationComponent from '../../Common/Pagination';
import { Form } from 'react-bootstrap';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { enableStaff, gettingStaffs } from '../../../redux/reducers/dashboard';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
var Staff = function () {
    var _a;
    var _b = useSelector(function (state) { return state.dashboard; }), err = _b.err, staffs = _b.staffs, loading = _b.loading;
    var userId = useSelector(function (state) { return state.auth; }).userId;
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var _c = useState(''), searchTerm = _c[0], setSearchTerm = _c[1];
    var _d = useState(staffs === null || staffs === void 0 ? void 0 : staffs.rows), staffData = _d[0], setStaffData = _d[1];
    var _e = useState(searchTerm), debouncedSearchTerm = _e[0], setDebouncedSearchTerm = _e[1];
    var _f = useState(0), pageIndexNo = _f[0], setPageIndexNo = _f[1];
    var _g = useState(20), pageSizeNo = _g[0], setPageSizeNo = _g[1];
    var _h = useState('firstname'), sortColumn = _h[0], setSortColumn = _h[1];
    var _j = useState('ASC'), sortOrder = _j[0], setSortOrder = _j[1];
    var _k = useState([]), flashMessages = _k[0], setFlashMessages = _k[1];
    var handleSearch = function (e) {
        e.preventDefault();
    };
    var handleChange = function (id, enabled) {
        setStaffData(function (prevData) {
            return prevData.map(function (staff) {
                return staff.id === id ? __assign(__assign({}, staff), { enabled: !enabled }) : staff;
            });
        });
        dispatch(enableStaff({ id: id, enabled: !enabled }));
    };
    var data = useMemo(function () { return staffData; }, [staffData]);
    var columns = useMemo(function () { return [
        {
            Header: 'First Name',
            id: 'firstname',
            accessor: function (row) { return "".concat(row.firstname.charAt(0).toUpperCase() + row.firstname.slice(1)); },
        },
        {
            Header: 'Last Name',
            id: 'lastname',
            accessor: function (row) { return "".concat(row.lastname.charAt(0).toUpperCase() + row.lastname.slice(1)); },
        },
        {
            Header: 'Email',
            accessor: function (row) { return row.email; },
            id: 'email'
        },
        {
            Header: 'Actions',
            width: '100px',
            disableSortBy: true,
            Cell: function (_a) {
                var row = _a.row;
                return (_jsx("div", { className: "text-center", children: _jsx(Link, { to: "/staff/staffs/edit/".concat(row.original.id), className: "btn btn-blue", children: "Edit" }) }));
            },
        },
        {
            Header: 'Active',
            width: '120px',
            disableSortBy: true,
            accessor: 'enabled',
            Cell: function (_a) {
                var row = _a.row;
                return (Number(userId) !== Number(row.original.id) && (_jsx("div", { className: "text-center", style: { maxWidth: '150px' }, children: _jsxs("label", { className: "switch", children: [_jsx("input", { type: "checkbox", name: "enabled_".concat(row.original.id), className: "change_status", checked: row.original.enabled, onChange: function () { return handleChange(row.original.id, row.original.enabled); } }), _jsx("div", { className: "slider round" })] }) })));
            }
        },
    ]; }, []);
    useEffect(function () {
        var handler = setTimeout(function () {
            setPageIndexNo(0);
            setDebouncedSearchTerm(searchTerm);
        }, 1000);
        return function () {
            clearTimeout(handler);
        };
    }, [searchTerm]);
    useEffect(function () {
        dispatch(gettingStaffs({ page: pageIndexNo, pageSize: pageSizeNo, search: debouncedSearchTerm, sortColumn: sortColumn, sortOrder: sortOrder }));
    }, [pageIndexNo, pageSizeNo, debouncedSearchTerm, sortColumn, sortOrder]);
    useEffect(function () {
        setStaffData(staffs === null || staffs === void 0 ? void 0 : staffs.rows);
    }, [staffs === null || staffs === void 0 ? void 0 : staffs.rows]);
    var _l = useTable({
        columns: columns,
        data: data,
        manualSortBy: true,
        initialState: { pageIndex: 0, pageSize: 20, },
    }, useSortBy, usePagination), getTableProps = _l.getTableProps, getTableBodyProps = _l.getTableBodyProps, headerGroups = _l.headerGroups, prepareRow = _l.prepareRow, page = _l.page, canPreviousPage = _l.canPreviousPage, canNextPage = _l.canNextPage, pageOptions = _l.pageOptions, gotoPage = _l.gotoPage, nextPage = _l.nextPage, previousPage = _l.previousPage, setPageSize = _l.setPageSize, _m = _l.state, pageIndex = _m.pageIndex, pageSize = _m.pageSize;
    var handleSort = function (columnId, canSort) {
        if (canSort) {
            setSortColumn(columnId);
            setSortOrder(function (prevSortOrder) { return (prevSortOrder === 'ASC' ? 'DESC' : 'ASC'); });
        }
    };
    return (_jsx("div", { className: "container content-container", children: _jsx("div", { className: "row", children: _jsxs("div", { className: "col-md-12", children: [flashMessages.map(function (flashMessage, index) { return (_jsx("div", { className: "cl-system-messages", children: _jsx("div", { className: "".concat(flashMessage.type, "-message"), children: flashMessage.message }) }, index)); }), _jsx("div", { className: "col-md-12 button-section text-xs-right text-md-end mb-3", children: _jsxs(Link, { to: "/staff/staffs/new", className: "btn btn-blue btn-lg", children: ["Add New ", _jsx(FontAwesomeIcon, { icon: faPlus })] }) }), _jsx("div", { className: "col-md-12 text-align-end", children: _jsx(Form, { onSubmit: handleSearch, className: "search-box", children: _jsxs(Form.Group, { controlId: "formSearch", className: 'd-flex justify-content-end align-items-center gap-2', children: [_jsx(Form.Label, { className: 'mb-0', children: "Search:" }), _jsx(Form.Control, { name: "search", type: "text", className: "form-control custom-width-20", value: searchTerm, onChange: function (e) { return setSearchTerm(e.target.value); } })] }) }) }), _jsxs("table", __assign({}, getTableProps(), { className: "data-table", children: [_jsx("thead", { children: headerGroups.map(function (headerGroup) { return (_jsx("tr", __assign({}, headerGroup.getHeaderGroupProps(), { children: headerGroup.headers.map(function (column) {
                                        var isSorted = sortColumn === column.id;
                                        var isSortedAsc = isSorted && sortOrder === 'ASC';
                                        var isSortedDesc = isSorted && sortOrder === 'DESC';
                                        return ((_jsxs("th", __assign({}, column.getHeaderProps({
                                            onClick: function () { return handleSort(column.id, column.canSort); },
                                        }), { style: { width: column === null || column === void 0 ? void 0 : column.width }, children: [column.render('Header'), column.canSort && (_jsx("span", { children: isSortedAsc ? ' 🔼' : isSortedDesc ? ' 🔽' : '' }))] }))));
                                    }) }))); }) }), _jsx("tbody", __assign({ className: 'dataTables_wrapper position-relative min-height-5rem' }, getTableBodyProps(), { children: loading ? (_jsx("div", { id: "contractor_datatable_processing", className: "dataTables_processing panel panel-default ".concat(loading ? 'd-block' : '', " "), children: "Processing..." })) : page.length === 0 ? (_jsx("tr", { children: _jsx("td", { colSpan: columns.length, className: 'text-center', children: "No matching records found" }) })) : (page.map(function (row) {
                                    prepareRow(row);
                                    return (_jsx("tr", __assign({}, row.getRowProps(), { children: row.cells.map(function (cell) { return (_jsx("td", __assign({}, cell.getCellProps(), { children: cell.render('Cell') }))); }) })));
                                })) }))] })), _jsx(PaginationComponent, { gotoPage: gotoPage, previousPage: previousPage, nextPage: nextPage, pageCount: Math.ceil((staffs === null || staffs === void 0 ? void 0 : staffs.count) / pageSizeNo), canPreviousPage: canPreviousPage, canNextPage: canNextPage, pageIndex: pageIndexNo, pageOptions: pageOptions, pageSize: (_a = staffs === null || staffs === void 0 ? void 0 : staffs.rows) === null || _a === void 0 ? void 0 : _a.length, setPageSize: setPageSize, totalCount: staffs === null || staffs === void 0 ? void 0 : staffs.count, fullCount: searchTerm ? staffs === null || staffs === void 0 ? void 0 : staffs.fullCount : 0, setPageIndexNo: setPageIndexNo })] }) }) }));
};
1;
export default Staff;

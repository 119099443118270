var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// CustomModal.tsx
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Form, FormGroup, } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { gettingProfileImage } from '../../redux/reducers/dashboard';
var REACT_APP_INVOICE_PDF_BUCKET_NAME_S3 = process.env.REACT_APP_INVOICE_PDF_BUCKET_NAME_S3;
import { Document, Page } from 'react-pdf';
var CustomModal = function (_a) {
    var show = _a.show, onClose = _a.onClose, addComment = _a.addComment, currentPdfPath = _a.currentPdfPath, _b = _a.title, title = _b === void 0 ? "" : _b, _c = _a.message, message = _c === void 0 ? "Your Message Goes Here!" : _c, _d = _a.closeButton, closeButton = _d === void 0 ? true : _d, _e = _a.scrollable, scrollable = _e === void 0 ? false : _e, downloadBtn = _a.downloadBtn, viewPdf = _a.viewPdf, addCommentStatus = _a.addCommentStatus, editCommentStatus = _a.editCommentStatus, text = _a.text;
    var _f = useForm(), register = _f.register, handleSubmit = _f.handleSubmit, reset = _f.reset, errors = _f.formState.errors;
    var _g = useState(''), pdfUrl = _g[0], setPdfUrl = _g[1];
    var dispatch = useDispatch();
    var _h = useState(), numPages = _h[0], setNumPages = _h[1];
    function onDocumentLoadSuccess(_a) {
        var numPages = _a.numPages;
        setNumPages(numPages);
    }
    var defaultAddComment = function (data) {
    };
    var handleDownload = function () {
        if (pdfUrl) {
            var link = document.createElement('a');
            link.href = pdfUrl;
            link.download = "".concat(title, ".pdf");
            link.click();
        }
    };
    useEffect(function () {
        dispatch(gettingProfileImage({ fileName: decodeURIComponent(currentPdfPath), bucketName: REACT_APP_INVOICE_PDF_BUCKET_NAME_S3 })).then(function (res) {
            if (res === null || res === void 0 ? void 0 : res.payload) {
                var linkSource = "data:application/pdf;base64,".concat(res === null || res === void 0 ? void 0 : res.payload.data);
                setPdfUrl(linkSource);
            }
        });
    }, [dispatch]);
    return (_jsxs(Modal, { show: show, onHide: onClose, scrollable: scrollable, children: [_jsx(Modal.Header, { closeButton: closeButton, children: title && _jsx(Modal.Title, { children: title }) }), _jsx(Modal.Body, { style: scrollable ? { maxHeight: '450px', overflowY: 'auto' } : undefined, children: (addCommentStatus || editCommentStatus) ? (_jsx(Form, { children: _jsxs(FormGroup, { className: "mb-3", children: [_jsx("textarea", __assign({}, register('text', { required: true }), { className: "form-control ".concat(errors.text ? 'is-invalid' : ''), defaultValue: editCommentStatus ? text : '' })), errors.text && _jsx("div", { className: "invalid-feedback", children: "Comment is required" })] }) })) : (pdfUrl ? (_jsx("div", { children: _jsx(Document, { file: pdfUrl, onLoadSuccess: onDocumentLoadSuccess, children: Array === null || Array === void 0 ? void 0 : Array.from(new Array(numPages), function (el, index) { return (_jsxs(_Fragment, { children: [_jsx(Page, { width: 480, pageNumber: index + 1 }, "page_".concat(index + 1)), _jsxs("p", { children: ["Page ", index + 1, " of ", numPages] })] })); }) }) })) : (_jsx("p", { children: "Loading PDF..." }))) }), _jsxs(Modal.Footer, { children: [(addCommentStatus || editCommentStatus) && (_jsx(Button, { className: 'btn btn-blue', onClick: handleSubmit(addComment || defaultAddComment), children: addCommentStatus ? 'Add Comment' : 'Edit Comment' })), closeButton && (_jsx(Button, { className: 'btn btn-red', onClick: onClose, children: "Close" })), downloadBtn && (_jsx(Button, { className: 'btn btn-gold', onClick: handleDownload, children: "Download" }))] })] }));
};
export default CustomModal;

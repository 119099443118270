import _ from 'lodash';
var getFilePath = function (url) {
    var urlObj = new URL(url);
    var filePath = urlObj.pathname;
    var fileName = filePath.substring(filePath.lastIndexOf('/') + 1);
    return fileName;
};
var generateFilename = function (fileExt) {
    return "file.".concat(fileExt);
};
var getFileExtension = function (filename) {
    var extensionRegex = /\.([0-9a-z]+)$/i;
    var match = filename.match(extensionRegex);
    if (match && match[1]) {
        return match[1].toLowerCase();
    }
    else {
        return '';
    }
};
var formatAmount = function (amount) {
    return _.replace(amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','), /(\.\d{2})?$/, function (match) { return match; });
};
export { getFilePath, generateFilename, getFileExtension, formatAmount };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { addingCategory, gettingSingleCategory, updatingCategory } from '../../../redux/reducers/dashboard';
import MessageBar from '../../Common/MessageBar';
import { useSelector } from 'react-redux';
var CategoryForm = function (_a) {
    var Addnew = _a.Addnew;
    var _b = useSelector(function (state) { return state.dashboard; }), err = _b.err, loading = _b.loading, currentCategory = _b.currentCategory;
    var dispatch = useDispatch();
    var id = useParams().id;
    var _c = useState(false), showBar = _c[0], setShowBar = _c[1];
    var _d = useState({
        type: '',
        message: '',
    }), messageBarProps = _d[0], setMessageBarProps = _d[1];
    var _e = useForm(), register = _e.register, handleSubmit = _e.handleSubmit, reset = _e.reset, errors = _e.formState.errors;
    useEffect(function () {
        if (id) {
            dispatch(gettingSingleCategory(id));
        }
    }, []);
    useEffect(function () {
        if (currentCategory) {
            reset(currentCategory);
        }
    }, [currentCategory, reset]);
    var onSubmit = function (data) {
        if (Addnew) {
            dispatch(addingCategory(data)).then(function (res) {
                var _a, _b;
                var success = (res === null || res === void 0 ? void 0 : res.payload).success;
                setShowBar(true);
                if (success === true) {
                    setMessageBarProps({
                        type: "success",
                        message: "Category add successfully!"
                    });
                    reset();
                }
                else {
                    setMessageBarProps({
                        type: "error",
                        message: (_b = (_a = res === null || res === void 0 ? void 0 : res.payload) === null || _a === void 0 ? void 0 : _a.err) === null || _b === void 0 ? void 0 : _b.message
                    });
                }
            });
        }
        else {
            dispatch(updatingCategory(data)).then(function (res) {
                var _a, _b;
                var success = (res === null || res === void 0 ? void 0 : res.payload).success;
                setShowBar(true);
                if (success === true) {
                    setMessageBarProps({
                        type: "success",
                        message: "Category updated successfully!"
                    });
                }
                else {
                    setMessageBarProps({
                        type: "error",
                        message: (_b = (_a = res === null || res === void 0 ? void 0 : res.payload) === null || _a === void 0 ? void 0 : _a.err) === null || _b === void 0 ? void 0 : _b.message
                    });
                }
            });
        }
    };
    return (_jsx(Container, { className: 'content-container', children: _jsxs("div", { className: "row", children: [showBar && (_jsx(MessageBar, { type: messageBarProps.type, message: messageBarProps.message })), _jsxs("div", { className: "col-md-4", children: [_jsxs(Link, { to: "/staff/category", className: "btn btn-red mb-3", children: [_jsx(FontAwesomeIcon, { icon: faArrowLeft }), " Back"] }), _jsxs("form", { className: "form-validation", onSubmit: handleSubmit(onSubmit), children: [_jsx("div", { className: "form-field", children: _jsxs("div", { className: "field-input", children: [_jsx("label", { className: 'mb-2', children: "Category Name" }), _jsx("input", __assign({}, register('name', { required: true }), { type: "text", className: "form-control ".concat(errors.name ? 'is-invalid' : ''), placeholder: "Enter Category Name" })), errors.name && (_jsx("span", { className: "invalid-feedback", children: "Category Name is required" }))] }) }), _jsx("div", { className: "cl-flex cl-flex-middle between-xs", children: _jsx("input", { type: "submit", name: "submit", className: "btn btn-blue pull-left", value: Addnew ? 'Save Category' : 'Update Category' }) })] })] })] }) }));
};
export default CategoryForm;

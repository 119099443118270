import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
// import logoWhite from '../../../assets/images/logo-white.png' as string;
var logoWhiteModule = require('../../assets/images/logo-white.png');
// Access the URL of the imported image
var logoWhiteUrl = logoWhiteModule.default;
var Footer = function () {
    return (_jsx("footer", { children: _jsx("div", { className: "container", children: _jsxs("div", { className: "row cl-flex cl-flex-middle", children: [_jsx("div", { className: "col-md-5 col-sm-4 col-xs-12", children: _jsx("img", { src: logoWhiteUrl, alt: "logo", style: { width: '70px' } }) }), _jsx("div", { className: "col-md-4 col-sm-4 col-xs-12", children: _jsx("div", { className: "footer-title", children: "Invoice HQ" }) }), _jsx("div", { className: "col-md-3 col-sm-4 col-xs-12", children: _jsx("div", { className: "footer-title", children: "The Community Co 2017" }) })] }) }) }));
};
export default Footer;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import CustomModal from '../../Common/CustomModal';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gettingComments, updatingComment } from '../../../redux/reducers/dashboard';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
var Comment = function (_a) {
    var _b, _c, _d;
    var comment = _a.comment, invoiceNumber = _a.invoiceNumber;
    var id = useParams().id;
    var dispatch = useDispatch();
    var _e = useState(false), showModal = _e[0], setShowModal = _e[1];
    var _f = useState(false), editCommentStatus = _f[0], setEditCommentStatus = _f[1];
    var handleShow = function () {
        setEditCommentStatus(true);
        setShowModal(true);
    };
    var addComment = function (data) {
        dispatch(updatingComment(__assign({ id: comment === null || comment === void 0 ? void 0 : comment.id, invoiceId: Number(id) }, data))).then(function (res) {
            var _a;
            if (((_a = res === null || res === void 0 ? void 0 : res.payload) === null || _a === void 0 ? void 0 : _a.success) === true && id) {
                dispatch(gettingComments(id));
                handleClose();
            }
        });
    };
    var handleClose = function () {
        setShowModal(false);
        setEditCommentStatus(false);
    };
    return (_jsxs("div", { className: "comment media row margin-left-rm", children: [_jsx("a", { className: "profile_image_anchor d-flex col-md-2", "data-close-others": "true", children: _jsx("span", { children: ((_b = comment === null || comment === void 0 ? void 0 : comment.user) === null || _b === void 0 ? void 0 : _b.profileImage) !== '' ? (_jsx("img", { className: "img-circle", src: "https://your-api-url.com/staff_staff_show_image?user=".concat(comment.userId), alt: "Username" })) : (_jsx("img", { className: "img-circle", src: "https://www.gravatar.com/avatar/".concat(encodeURIComponent(comment === null || comment === void 0 ? void 0 : comment.userId), "?d=identicon"), alt: "profileImage not found" })) }) }), _jsxs("div", { className: "media-body d-flex flex-column gap-2 col-md-10", children: [_jsxs("div", { className: "d-flex justify-content-between align-items-center", children: [_jsx("h6", { className: "mt-0", children: _jsxs("strong", { children: [(_c = comment === null || comment === void 0 ? void 0 : comment.user) === null || _c === void 0 ? void 0 : _c.firstname, " ", (_d = comment === null || comment === void 0 ? void 0 : comment.user) === null || _d === void 0 ? void 0 : _d.lastname] }) }), _jsx("small", { className: "create_date", children: new Date(comment === null || comment === void 0 ? void 0 : comment.createdAt).toLocaleString('en-US', {
                                    day: '2-digit',
                                    month: 'short',
                                    year: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    hour12: true,
                                }) })] }), _jsxs("div", { id: "comment-text_".concat(comment === null || comment === void 0 ? void 0 : comment.id), className: "comment-text", children: [comment === null || comment === void 0 ? void 0 : comment.text, ' ', ((comment === null || comment === void 0 ? void 0 : comment.createdAt) !== (comment === null || comment === void 0 ? void 0 : comment.updatedAt)) && (_jsxs("small", { children: ["(Edited - ", new Date(comment === null || comment === void 0 ? void 0 : comment.updatedAt).toLocaleString('en-US', {
                                        day: '2-digit',
                                        month: 'short',
                                        year: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        hour12: true,
                                    }), ")"] }))] }), _jsxs("a", { href: "#", onClick: handleShow, children: [_jsx(FontAwesomeIcon, { icon: faPencilAlt }), " Edit"] }), editCommentStatus && (_jsx(CustomModal, { show: showModal, onClose: handleClose, addComment: addComment, editCommentStatus: editCommentStatus, title: invoiceNumber, text: comment === null || comment === void 0 ? void 0 : comment.text, message: "Add", closeButton: true, scrollable: true }))] })] }));
};
export default Comment;

import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import Store from "./redux/store";
var persistor = persistStore(Store);
ReactDOM.render(_jsx(Provider, { store: Store, children: _jsx(PersistGate, { loading: null, persistor: persistor, children: _jsx(App, {}) }) }), document.getElementById("root"));

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { updatePassword } from '../../../redux/reducers/auth';
import { useSelector } from 'react-redux';
import MessageBar from '../../Common/MessageBar';
var UpdatePasswordForm = function () {
    var _a, _b;
    var _c = useSelector(function (state) { return state.auth; }), err = _c.err, loading = _c.loading, message = _c.message;
    var _d = useState(false), showBar = _d[0], setShowBar = _d[1];
    var _e = useState({
        type: '',
        message: '',
    }), messageBarProps = _e[0], setMessageBarProps = _e[1];
    var _f = useForm(), register = _f.register, handleSubmit = _f.handleSubmit, watch = _f.watch, reset = _f.reset, errors = _f.formState.errors;
    var dispatch = useDispatch();
    var newPassword = watch('newPassword');
    var onSubmit = function (data) {
        dispatch(updatePassword(__assign({}, data))).then(function (res) {
            var _a, _b, _c;
            var success = (res === null || res === void 0 ? void 0 : res.payload).success;
            setShowBar(true);
            reset();
            if (success === true) {
                setMessageBarProps({
                    type: "success",
                    message: (_a = res === null || res === void 0 ? void 0 : res.payload) === null || _a === void 0 ? void 0 : _a.message
                });
            }
            else {
                setMessageBarProps({
                    type: "error",
                    message: (_c = (_b = res === null || res === void 0 ? void 0 : res.payload) === null || _b === void 0 ? void 0 : _b.err) === null || _c === void 0 ? void 0 : _c.message
                });
            }
        });
    };
    return (_jsx("div", { className: "container content-container", children: _jsxs("div", { className: "row", children: [showBar && (_jsx(MessageBar, { type: messageBarProps.type, message: messageBarProps.message })), _jsx("div", { className: "col-md-4", children: _jsxs("form", { onSubmit: handleSubmit(onSubmit), className: "form-horizontal form-bordered", children: [_jsxs("div", { className: "form-field", children: [_jsx("label", { className: 'mb-2', children: "Current Password" }), _jsx("input", __assign({ type: "password", className: "form-control required ".concat(errors.currentPassword ? 'is-invalid' : '') }, register('currentPassword', { required: true }))), errors.currentPassword && _jsx("span", { className: "text-danger", children: "Current Password is required" })] }), _jsxs("div", { className: "form-field", children: [_jsx("label", { className: 'mb-2', children: "New Password" }), _jsx("input", __assign({ type: "password", className: "form-control required ".concat(errors.newPassword ? 'is-invalid' : '') }, register('newPassword', { required: true }))), errors.newPassword && _jsx("span", { className: "text-danger", children: "New Password is required" })] }), _jsxs("div", { className: "form-field", children: [_jsx("label", { className: 'mb-2', children: "Repeat New Password" }), _jsx("input", __assign({ type: "password", className: "form-control required ".concat(errors.repeatNewPassword ? 'is-invalid' : '') }, register('repeatNewPassword', {
                                        required: true,
                                        validate: function (value) { return value === newPassword || "Passwords do not match"; }
                                    }))), errors.repeatNewPassword && (_jsxs("span", { className: "text-danger", children: [" ", ((_a = errors.repeatNewPassword) === null || _a === void 0 ? void 0 : _a.message) !== '' ? (_b = errors.repeatNewPassword) === null || _b === void 0 ? void 0 : _b.message : 'Repeat New Password is required'] }))] }), _jsx("div", { className: "form-field mt-1", children: _jsx("input", { className: "btn btn-blue", type: "submit", value: "Save" }) })] }) })] }) }));
};
export default UpdatePasswordForm;

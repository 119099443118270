var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../config/axios";
var initialState = {
    username: "",
    profileImage: "",
    email: "",
    password: "",
    confirmPassword: "",
    message: "",
    agreePolicy: false,
    enabled: null,
    loading: false,
    err: "",
    done: false,
    emailSent: null,
    signup: null,
    routeLoading: true,
    isLogged: null,
    token: "",
    userId: "",
    roles: "",
    firstname: "",
    middlename: "",
    lastname: "",
    phoneNo: "",
};
export var SignIn = createAsyncThunk("/user/signIn", function (user, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, axios.post("/auth/signin", user)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_1 = _a.sent();
                if (err_1.response && err_1.response.data) {
                    return [2 /*return*/, thunkAPI.rejectWithValue({
                            err: err_1.response.data,
                            status: err_1.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, thunkAPI.rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var getDetails = createAsyncThunk("/user/getDetails", function (token_1, _a) { return __awaiter(void 0, [token_1, _a], void 0, function (token, _b) {
    var response, err_2;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 2, , 3]);
                return [4 /*yield*/, axios.get("/auth/getDetails", {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _c.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_2 = _c.sent();
                if (err_2.response && err_2.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_2.response.data,
                            status: err_2.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var NewUser = createAsyncThunk("user/signUp", function (user, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, axios.post("/auth/signUp", user)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_3 = _a.sent();
                if (err_3.response && err_3.response.data) {
                    return [2 /*return*/, thunkAPI.rejectWithValue({
                            err: err_3.response.data,
                            status: err_3.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, thunkAPI.rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var SendEmail = createAsyncThunk("user/sendEmail", function (email, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, axios.post("/auth/forgetpassword", { email: email })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_4 = _a.sent();
                if (err_4.response && err_4.response.data) {
                    return [2 /*return*/, thunkAPI.rejectWithValue({
                            err: err_4.response.data,
                            status: err_4.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, thunkAPI.rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var EditMyProfile = createAsyncThunk("user/EditMyProfile", function (user_1, _a) { return __awaiter(void 0, [user_1, _a], void 0, function (user, _b) {
    var state, token, response, err_5;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.post("/auth/edit-profile", user, {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_5 = _d.sent();
                if (err_5.response && err_5.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_5.response.data,
                            status: err_5.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var uploadProfileImage = createAsyncThunk("/user/uploadProfileImage", function (user, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, axios.put("/auth/resetpassword", { password: user.password }, {
                        headers: {
                            Authorization: "bearer ".concat(user.token),
                        },
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_6 = _a.sent();
                if (err_6.response && err_6.response.data) {
                    return [2 /*return*/, thunkAPI.rejectWithValue({
                            err: err_6.response.data,
                            status: err_6.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, thunkAPI.rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var resetPassword = createAsyncThunk("/user/resetPassword", function (user, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, axios.put("/auth/resetpassword", { password: user.password }, {
                        headers: {
                            Authorization: "bearer ".concat(user.token),
                        },
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_7 = _a.sent();
                if (err_7.response && err_7.response.data) {
                    return [2 /*return*/, thunkAPI.rejectWithValue({
                            err: err_7.response.data,
                            status: err_7.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, thunkAPI.rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var updatePassword = createAsyncThunk("/user/updatePassword", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_8;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.put("/users/update-password", data, {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_8 = _d.sent();
                if (err_8.response && err_8.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_8.response.data,
                            status: err_8.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
var authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        SetState: function (state, action) {
            var _a = action.payload, field = _a.field, value = _a.value;
            state[field] = value;
        },
        ClearState: function (state) {
            return __assign(__assign({}, initialState), { routeLoading: state.routeLoading });
        },
        logout: function (state) {
            return __assign(__assign({}, initialState), { routeLoading: state.routeLoading, isLogged: false });
        },
    },
    extraReducers: function (builder) {
        builder
            .addCase(SignIn.pending, function (state) {
            state.loading = true;
        })
            .addCase(SignIn.fulfilled, function (state, action) {
            var _a = action.payload, user = _a.user, token = _a.token;
            state.email = user.email;
            state.userId = user.id;
            state.token = token.token;
            state.loading = false;
            state.done = true;
            state.isLogged = true;
            state.roles = user.roles;
            state.profileImage = user.profileImage;
            state.firstname = user.firstname;
            state.middlename = user.middlename;
            state.lastname = user.lastname;
            state.phoneNo = user.phoneNo;
            state.enabled = user.enabled;
            state.username = user.username;
        })
            .addCase(SignIn.rejected, function (state, action) {
            state.loading = false;
            state.err = (action.payload.err).message;
        })
            .addCase(getDetails.pending, function (state) {
            state.loading = true;
        })
            .addCase(getDetails.fulfilled, function (state, action) {
            var _a = action.payload, user = _a.user, token = _a.token;
            state.email = user.email;
            state.userId = user.id;
            state.token = token.token;
            state.loading = false;
            state.done = true;
            state.isLogged = true;
            state.roles = user.roles;
            state.profileImage = user.profileImage;
            state.firstname = user.firstname;
            state.middlename = user.middlename;
            state.lastname = user.lastname;
            state.phoneNo = user.phoneNo;
            state.enabled = user.enabled;
            state.username = user.username;
        })
            .addCase(getDetails.rejected, function (state, action) {
            state.loading = false;
            state.err = (action.payload.err).message;
        })
            .addCase(NewUser.pending, function (state) {
            state.loading = true;
        })
            .addCase(NewUser.fulfilled, function (state, action) {
            var _a = action.payload, user = _a.user, token = _a.token;
            state.username = user.username;
            state.email = user.email;
            state.userId = user.id;
            state.token = token.token;
            state.message = token;
            state.loading = false;
            state.isLogged = true;
            state.err = "";
            state.signup = true;
            state.roles = user.roles;
            state.profileImage = user.profileImage;
            state.firstname = user.firstname;
            state.middlename = user.middlename;
            state.lastname = user.lastname;
            state.phoneNo = user.phoneNo;
            state.enabled = user.enabled;
        })
            .addCase(NewUser.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(SendEmail.pending, function (state) {
            state.loading = true;
        })
            .addCase(SendEmail.fulfilled, function (state, action) {
            state.loading = false;
            state.err = "";
            state.emailSent = true;
            state.message = action.payload;
        })
            .addCase(SendEmail.rejected, function (state, action) {
            state.loading = false;
            state.err = (action.payload.err).message;
        })
            .addCase(EditMyProfile.pending, function (state) {
            state.loading = true;
        })
            .addCase(EditMyProfile.fulfilled, function (state, action) {
            var user = action.payload.user;
            state.username = user.username;
            state.email = user.email;
            state.firstname = user.firstname;
            state.middlename = user.middlename;
            state.profileImage = user.profileImage;
            state.lastname = user.lastname;
            state.phoneNo = user.phoneNo;
            state.loading = false;
            state.err = "";
        })
            .addCase(EditMyProfile.rejected, function (state, action) {
            state.loading = false;
            state.err = (action.payload.err).message;
        })
            .addCase(uploadProfileImage.pending, function (state) {
            state.loading = true;
        })
            .addCase(uploadProfileImage.fulfilled, function (state, action) {
            var user = action.payload.user;
            state.username = user.username;
            state.email = user.email;
            state.firstname = user.firstname;
            state.middlename = user.middlename;
            state.lastname = user.lastname;
            state.phoneNo = user.phoneNo;
            state.loading = false;
            state.err = "";
        })
            .addCase(uploadProfileImage.rejected, function (state, action) {
            state.loading = false;
            state.err = (action.payload.err).message;
        })
            .addCase(updatePassword.pending, function (state) {
            state.loading = true;
            state.err = "";
            state.message = "";
        })
            .addCase(updatePassword.fulfilled, function (state, action) {
            state.loading = false;
            state.err = "";
            state.done = true;
            state.message = action.payload;
        })
            .addCase(updatePassword.rejected, function (state, action) {
            state.loading = false;
            state.done = false;
            state.message = "";
            state.err = (action.payload.err).message;
        })
            .addCase(resetPassword.pending, function (state) {
            state.loading = true;
        })
            .addCase(resetPassword.fulfilled, function (state, action) {
            state.loading = false;
            state.err = "";
            state.done = true;
            state.message = action.payload;
        })
            .addCase(resetPassword.rejected, function (state, action) {
            state.loading = false;
            state.done = false;
            state.err = (action.payload.err).message;
        });
    },
});
export var SetState = (_a = authSlice.actions, _a.SetState), ClearState = _a.ClearState, logout = _a.logout;
export default authSlice.reducer;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
var logo = require('../../assets/images/logo.png');
var logoUrl = logo.default;
var CheckEmail = function () {
    return (_jsx("div", { className: 'main-content', children: _jsx(Container, { children: _jsx("div", { className: "login", id: "login", children: _jsx(Row, { className: "justify-content-center align-items-center", children: _jsx(Col, { md: 6, children: _jsx(Row, { className: "justify-content-center align-items-center full-height-viewport", children: _jsxs(Col, { md: 9, sm: 6, className: "text-center", children: [_jsx("div", { className: "security-logo", children: _jsx(Image, { src: logoUrl, alt: "Logo" }) }), _jsx("div", { className: "d-flex justify-content-center align-items-center mt-3", children: _jsx(Link, { to: "/auth/signin", children: _jsx(Button, { type: "submit", className: "btn btn-light-blue", children: "Back to login" }) }) }), _jsx("p", { className: "text-center my-3", children: "An email has been sent. It contains a link you must click to reset your password. Note: You can only request a new password within 24 hours. If you don't get an email check your spam folder or try again." })] }) }) }) }) }) }) }));
};
export default CheckEmail;

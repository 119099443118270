var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../config/axios";
var initialState = {
    bodyCorporateUsers: {
        rows: [],
        count: 0,
        fullCount: 0,
    },
    contractors: {
        rows: [],
        count: 0,
        fullCount: 0,
    },
    categories: {
        rows: [],
        count: 0,
        fullCount: 0,
    },
    funds: {
        rows: [],
        count: 0,
        fullCount: 0,
    },
    newInvoices: {
        rows: [],
        count: 0,
        fullCount: 0,
    },
    staffs: {
        rows: [],
        count: 0,
        fullCount: 0,
    },
    bodyCorporates: {
        rows: [],
        count: 0,
        fullCount: 0,
    },
    comments: {
        rows: [],
        count: 0,
    },
    notifications: {
        rows: [],
        count: 0,
        unReadCount: 0
    },
    filterData: {
        data: [],
        typefilter: '',
    },
    currentInvoice: {},
    currentFund: {},
    currentCategory: {},
    currentBodyCorporateUser: {},
    currentBodyCorporate: {},
    currentContractor: {},
    currentStaff: {},
    routeLoading: true,
    preSignedUrl: '',
    profileImage: '',
    loading: false,
    invoiceLoading: false,
    totalPages: 0,
    err: "",
    done: false,
    isLogged: null,
};
export var gettingBodyCorporateUsers = createAsyncThunk("/dashboard/staff/body-corporate-users", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_1;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.get("/users/staff/body-corporate-users", {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                        params: {
                            page: data.page,
                            pageSize: data.pageSize,
                            search: data.search,
                            sortColumn: data.sortColumn,
                            sortOrder: data.sortOrder,
                        }
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_1 = _d.sent();
                if (err_1.response && err_1.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_1.response.data,
                            status: err_1.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var gettingStaffs = createAsyncThunk("/dashboard/staff/gettingStaffs", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_2;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.get("/users/staff/staffs", {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                        params: {
                            page: data.page,
                            pageSize: data.pageSize,
                            search: data.search,
                            sortColumn: data.sortColumn,
                            sortOrder: data.sortOrder,
                        }
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_2 = _d.sent();
                if (err_2.response && err_2.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_2.response.data,
                            status: err_2.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var gettingPreSignedUrl = createAsyncThunk("/auth/gettingPreSignedUrl", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_3;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.get("/auth/get-presigned-url", {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                        params: {
                            bucketName: data.bucketName,
                            fileName: data.fileName,
                        }
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_3 = _d.sent();
                if (err_3.response && err_3.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_3.response.data,
                            status: err_3.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var gettingProfileImage = createAsyncThunk("/auth/gettingProfileImage", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_4;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.post("/users/profile-image", data, {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_4 = _d.sent();
                if (err_4.response && err_4.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_4.response.data,
                            status: err_4.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var uploadingProfileImage = createAsyncThunk("/auth/uploadingProfileImage", function (selectedFile_1, _a) { return __awaiter(void 0, [selectedFile_1, _a], void 0, function (selectedFile, _b) {
    var state, preSignedUrl, response, err_5;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 2, , 3]);
                state = getState();
                preSignedUrl = (state === null || state === void 0 ? void 0 : state.dashboard).preSignedUrl;
                return [4 /*yield*/, axios.put(preSignedUrl, selectedFile, {
                        headers: {
                            'Content-Type': "image/jpeg",
                        },
                    })];
            case 1:
                response = _c.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_5 = _c.sent();
                if (err_5.response && err_5.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_5.response.data,
                            status: err_5.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var resetPassword = createAsyncThunk("/dashboard/profile/resetPassword", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_6;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.get("/users/staff/body-corporate-users", {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_6 = _d.sent();
                if (err_6.response && err_6.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_6.response.data,
                            status: err_6.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var gettingContractors = createAsyncThunk("/dashboard/gettingContractors", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_7;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.get("/users/staff/contractors", {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                        params: {
                            page: data.page,
                            pageSize: data.pageSize,
                            search: data.search,
                            sortColumn: data.sortColumn,
                            sortOrder: data.sortOrder,
                        }
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_7 = _d.sent();
                if (err_7.response && err_7.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_7.response.data,
                            status: err_7.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var gettingcategories = createAsyncThunk("/dashboard/gettingcategories", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_8;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.get("/users/staff/category", {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                        params: {
                            page: data.page,
                            pageSize: data.pageSize,
                            search: data.search,
                            sortColumn: data.sortColumn,
                            sortOrder: data.sortOrder,
                        }
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_8 = _d.sent();
                if (err_8.response && err_8.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_8.response.data,
                            status: err_8.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var gettingFunds = createAsyncThunk("/dashboard/gettingFunds", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_9;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.get("/users/staff/fund", {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                        params: {
                            page: data.page,
                            pageSize: data.pageSize,
                            search: data.search,
                            sortColumn: data.sortColumn,
                            sortOrder: data.sortOrder,
                        }
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_9 = _d.sent();
                if (err_9.response && err_9.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_9.response.data,
                            status: err_9.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var gettingComments = createAsyncThunk("/dashboard/gettingComments", function (invoiceId_1, _a) { return __awaiter(void 0, [invoiceId_1, _a], void 0, function (invoiceId, _b) {
    var state, token, response, err_10;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.get("/users/staff/get-all-comments", {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                        params: {
                            invoiceId: invoiceId
                        }
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_10 = _d.sent();
                if (err_10.response && err_10.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_10.response.data,
                            status: err_10.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var gettingNotifications = createAsyncThunk("/dashboard/gettingNotifications", function (_1, _a) { return __awaiter(void 0, [_1, _a], void 0, function (_, _b) {
    var state, token, response, err_11;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.get("/users/staff/get-all-notifications", {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_11 = _d.sent();
                if (err_11.response && err_11.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_11.response.data,
                            status: err_11.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var clearNotifications = createAsyncThunk("/dashboard/clearNotifications", function (_1, _a) { return __awaiter(void 0, [_1, _a], void 0, function (_, _b) {
    var state, token, response, err_12;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.get("/users/staff/clear-notifications", {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_12 = _d.sent();
                if (err_12.response && err_12.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_12.response.data,
                            status: err_12.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var gettingBodyCorporates = createAsyncThunk("/dashboard/gettingBodyCorporates", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_13;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.get("/users/staff/body-corporates", {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                        params: {
                            page: data.page,
                            pageSize: data.pageSize,
                            search: data.search,
                            sortColumn: data.sortColumn,
                            sortOrder: data.sortOrder,
                        }
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_13 = _d.sent();
                if (err_13.response && err_13.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_13.response.data,
                            status: err_13.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var gettingAllInvoices = createAsyncThunk("/dashboard/gettingAllInvoices", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_14;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.get("/users/staff/get-all-invoices", {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                        params: {
                            page: data.page,
                            pageSize: data.pageSize,
                            search: data.search,
                            invoiceStatus: data.invoiceStatus,
                            sortColumn: data.sortColumn,
                            sortOrder: data.sortOrder,
                        }
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_14 = _d.sent();
                if (err_14.response && err_14.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_14.response.data,
                            status: err_14.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var gettingBodycorporateInvoices = createAsyncThunk("/dashboard/gettingBodycorporateInvoices", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_15;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.get("/users/staff/get-bodycorporate-invoices", {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                        params: {
                            page: data.page,
                            pageSize: data.pageSize,
                            search: data.search,
                            invoiceStatus: data.invoiceStatus,
                            bodyCorporateId: data.bodyCorporateId,
                            sortColumn: data.sortColumn,
                            sortOrder: data.sortOrder,
                        }
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_15 = _d.sent();
                if (err_15.response && err_15.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_15.response.data,
                            status: err_15.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var getUserAcceptedfilterList = createAsyncThunk("/dashboard/getUserAcceptedfilterList", function (bodyCorporateId_1, _a) { return __awaiter(void 0, [bodyCorporateId_1, _a], void 0, function (bodyCorporateId, _b) {
    var state, token, response, err_16;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.get("/users/accepted-initial-filter", {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                        params: {
                            bodyCorporateId: bodyCorporateId
                        }
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_16 = _d.sent();
                if (err_16.response && err_16.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_16.response.data,
                            status: err_16.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var gettingSingleNewInvoices = createAsyncThunk("/dashboard/gettingSingleNewInvoices", function (invoiceId_1, _a) { return __awaiter(void 0, [invoiceId_1, _a], void 0, function (invoiceId, _b) {
    var state, token, response, err_17;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.get("/users/staff/get-single-invoice", {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                        params: {
                            invoiceId: invoiceId
                        }
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_17 = _d.sent();
                if (err_17.response && err_17.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_17.response.data,
                            status: err_17.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var gettingSingleBodyCorporateUser = createAsyncThunk("/dashboard/gettingSingleBodyCorporateUser", function (bodyCorporateUserId_1, _a) { return __awaiter(void 0, [bodyCorporateUserId_1, _a], void 0, function (bodyCorporateUserId, _b) {
    var state, token, response, err_18;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.get("/users/staff/get-single-body-corporate-user", {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                        params: {
                            bodyCorporateUserId: bodyCorporateUserId
                        }
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_18 = _d.sent();
                if (err_18.response && err_18.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_18.response.data,
                            status: err_18.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var gettingBodyCorporatesByUser = createAsyncThunk("/dashboard/gettingBodyCorporatesByUser", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_19;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.get("/users/get-body-corporates-by-user", {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_19 = _d.sent();
                if (err_19.response && err_19.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_19.response.data,
                            status: err_19.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var gettingSingleContractor = createAsyncThunk("/dashboard/gettingSingleContractor", function (contractorId_1, _a) { return __awaiter(void 0, [contractorId_1, _a], void 0, function (contractorId, _b) {
    var state, token, response, err_20;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.get("/users/staff/get-single-contractor", {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                        params: {
                            contractorId: contractorId
                        }
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_20 = _d.sent();
                if (err_20.response && err_20.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_20.response.data,
                            status: err_20.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var gettingSingleStaff = createAsyncThunk("/dashboard/gettingSingleStaff", function (staffId_1, _a) { return __awaiter(void 0, [staffId_1, _a], void 0, function (staffId, _b) {
    var state, token, response, err_21;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.get("/users/staff/get-single-staff", {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                        params: {
                            staffId: staffId
                        }
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_21 = _d.sent();
                if (err_21.response && err_21.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_21.response.data,
                            status: err_21.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var gettingSingleCategory = createAsyncThunk("/dashboard/gettingSingleCategory", function (categoryId_1, _a) { return __awaiter(void 0, [categoryId_1, _a], void 0, function (categoryId, _b) {
    var state, token, response, err_22;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.get("/users/staff/get-single-category", {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                        params: {
                            categoryId: categoryId
                        }
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_22 = _d.sent();
                if (err_22.response && err_22.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_22.response.data,
                            status: err_22.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var gettingSingleFund = createAsyncThunk("/dashboard/gettingSingleFund", function (fundId_1, _a) { return __awaiter(void 0, [fundId_1, _a], void 0, function (fundId, _b) {
    var state, token, response, err_23;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.get("/users/staff/get-single-fund", {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                        params: {
                            fundId: fundId
                        }
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_23 = _d.sent();
                if (err_23.response && err_23.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_23.response.data,
                            status: err_23.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var gettingSingleBodyCorporate = createAsyncThunk("/dashboard/gettingSingleBodyCorporate", function (bodyCorporateId_1, _a) { return __awaiter(void 0, [bodyCorporateId_1, _a], void 0, function (bodyCorporateId, _b) {
    var state, token, response, err_24;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.get("/users/staff/get-single-body-corporate", {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                        params: {
                            bodyCorporateId: bodyCorporateId
                        }
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_24 = _d.sent();
                if (err_24.response && err_24.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_24.response.data,
                            status: err_24.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var updateBodyValidate = createAsyncThunk("/dashboard/updateBodyValidate", function (id_1, _a) { return __awaiter(void 0, [id_1, _a], void 0, function (id, _b) {
    var state, token, response, err_25;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.put("/users/staff/body-corporate-validate", { id: id }, {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_25 = _d.sent();
                if (err_25.response && err_25.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_25.response.data,
                            status: err_25.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var enableStaff = createAsyncThunk("/dashboard/enableStaff", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_26;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.put("/users/staff/enable-staff", data, {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_26 = _d.sent();
                if (err_26.response && err_26.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_26.response.data,
                            status: err_26.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var addingContractor = createAsyncThunk("/dashboard/addingContractor", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_27;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.post("/users/staff/add-contractor", data, {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_27 = _d.sent();
                if (err_27.response && err_27.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_27.response.data,
                            status: err_27.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var getFilterOptions = createAsyncThunk("/dashboard/getFilterOptions", function (filterData_1, _a) { return __awaiter(void 0, [filterData_1, _a], void 0, function (filterData, _b) {
    var state, token, response, err_28;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.get("/users/staff/get-filter-options", {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                        params: __assign({}, filterData)
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_28 = _d.sent();
                if (err_28.response && err_28.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_28.response.data,
                            status: err_28.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var getFilterStartOptions = createAsyncThunk("/dashboard/getFilterStartOptions", function (bodyCorporateIds_1, _a) { return __awaiter(void 0, [bodyCorporateIds_1, _a], void 0, function (bodyCorporateIds, _b) {
    var state, token, response, err_29;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.get("/users/user/get-startup-filter", {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                        params: {
                            bodyCorporateIds: bodyCorporateIds
                        }
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_29 = _d.sent();
                if (err_29.response && err_29.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_29.response.data,
                            status: err_29.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var gettingStaffAcceptedInvoices = createAsyncThunk("/dashboard/gettingStaffAcceptedInvoices", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_30;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.post("/users/staff/accepted-invoices", data, {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_30 = _d.sent();
                if (err_30.response && err_30.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_30.response.data,
                            status: err_30.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var gettingUserAcceptedInvoices = createAsyncThunk("/dashboard/gettingUserAcceptedInvoices", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_31;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.post("/users/accepted-invoices", data, {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_31 = _d.sent();
                if (err_31.response && err_31.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_31.response.data,
                            status: err_31.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var gettingStaffReports = createAsyncThunk("/dashboard/gettingStaffReports", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_32;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.post("/users/staff/reports", data, {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_32 = _d.sent();
                if (err_32.response && err_32.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_32.response.data,
                            status: err_32.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var sendingInvoiceGroup = createAsyncThunk("/dashboard/sendingInvoiceGroup", function (invoiceIds_1, _a) { return __awaiter(void 0, [invoiceIds_1, _a], void 0, function (invoiceIds, _b) {
    var state, token, response, err_33;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.post("/users/staff/send-invoice-group", { invoiceIds: invoiceIds }, {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_33 = _d.sent();
                if (err_33.response && err_33.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_33.response.data,
                            status: err_33.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var changePendingInvoiceStatus = createAsyncThunk("/dashboard/changePendingInvoiceStatus", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_34;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.put("/users/staff/change-status", data, {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_34 = _d.sent();
                if (err_34.response && err_34.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_34.response.data,
                            status: err_34.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var changePendingInvoiceStatusByuser = createAsyncThunk("/dashboard/changePendingInvoiceStatusByuser", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_35;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.put("/users/staff/change-status-user", data, {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_35 = _d.sent();
                if (err_35.response && err_35.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_35.response.data,
                            status: err_35.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var remainderInvoiceGroup = createAsyncThunk("/dashboard/remainderInvoiceGroup", function (invoiceIds_1, _a) { return __awaiter(void 0, [invoiceIds_1, _a], void 0, function (invoiceIds, _b) {
    var state, token, response, err_36;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.put("/users/staff/reminder/group", { invoiceIds: invoiceIds }, {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_36 = _d.sent();
                if (err_36.response && err_36.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_36.response.data,
                            status: err_36.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var addingComment = createAsyncThunk("/dashboard/addingComment", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_37;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.post("/users/staff/add-comment", data, {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_37 = _d.sent();
                if (err_37.response && err_37.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_37.response.data,
                            status: err_37.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var addingCategory = createAsyncThunk("/dashboard/addingCategory", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_38;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.post("/users/staff/add-category", data, {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_38 = _d.sent();
                if (err_38.response && err_38.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_38.response.data,
                            status: err_38.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var addingFund = createAsyncThunk("/dashboard/addingFund", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_39;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.post("/users/staff/add-fund", data, {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_39 = _d.sent();
                if (err_39.response && err_39.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_39.response.data,
                            status: err_39.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var addBodyCorporateUser = createAsyncThunk("/dashboard/addBodyCorporateUser", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_40;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.post("/users/staff/add-body-corporate-user", data, {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_40 = _d.sent();
                if (err_40.response && err_40.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_40.response.data,
                            status: err_40.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var addStaff = createAsyncThunk("/dashboard/addStaff", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_41;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.post("/users/staff/add-staff", data, {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_41 = _d.sent();
                if (err_41.response && err_41.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_41.response.data,
                            status: err_41.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var addNewInvoice = createAsyncThunk("/dashboard/addNewInvoice", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_42;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.post("/users/staff/add-new-invoice", data, {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_42 = _d.sent();
                if (err_42.response && err_42.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_42.response.data,
                            status: err_42.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var addingBodyCorporate = createAsyncThunk("/dashboard/addingBodyCorporate", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_43;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.post("/users/staff/add-body-corporate", data, {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_43 = _d.sent();
                if (err_43.response && err_43.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_43.response.data,
                            status: err_43.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var updatingStaff = createAsyncThunk("/dashboard/updatingStaff", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_44;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.put("/users/staff/update-staff", data, {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_44 = _d.sent();
                if (err_44.response && err_44.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_44.response.data,
                            status: err_44.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var updatingBodyCorporateUser = createAsyncThunk("/dashboard/updatingBodyCorporateUser", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_45;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.put("/users/staff/update-body-corporate-user", data, {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_45 = _d.sent();
                if (err_45.response && err_45.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_45.response.data,
                            status: err_45.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var updatingBodyCorporate = createAsyncThunk("/dashboard/updatingBodyCorporate", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_46;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.put("/users/staff/update-body-corporate", data, {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_46 = _d.sent();
                if (err_46.response && err_46.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_46.response.data,
                            status: err_46.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var updatingComment = createAsyncThunk("/dashboard/updatingComment", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_47;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.put("/users/staff/update-comment", data, {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_47 = _d.sent();
                if (err_47.response && err_47.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_47.response.data,
                            status: err_47.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var deleteInvoice = createAsyncThunk("/dashboard/deleteInvoice", function (invoiceId_1, _a) { return __awaiter(void 0, [invoiceId_1, _a], void 0, function (invoiceId, _b) {
    var state, token, response, err_48;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.delete("/users/staff/delete-invoice", {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                        params: {
                            invoiceId: invoiceId
                        }
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_48 = _d.sent();
                if (err_48.response && err_48.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_48.response.data,
                            status: err_48.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var updatingInvoice = createAsyncThunk("/dashboard/updatingInvoice", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_49;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.put("/users/staff/update-new-invoice", data, {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_49 = _d.sent();
                if (err_49.response && err_49.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_49.response.data,
                            status: err_49.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var updatingcontractor = createAsyncThunk("/dashboard/updatingcontractor", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_50;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.put("/users/staff/update-contractor", data, {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_50 = _d.sent();
                if (err_50.response && err_50.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_50.response.data,
                            status: err_50.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var updatingCategory = createAsyncThunk("/dashboard/updatingCategory", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_51;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.put("/users/staff/update-category", data, {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_51 = _d.sent();
                if (err_51.response && err_51.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_51.response.data,
                            status: err_51.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var updatingFund = createAsyncThunk("/dashboard/updatingFund", function (data_1, _a) { return __awaiter(void 0, [data_1, _a], void 0, function (data, _b) {
    var state, token, response, err_52;
    var _c;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                state = getState();
                token = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.token;
                return [4 /*yield*/, axios.put("/users/staff/update-fund", data, {
                        headers: {
                            Authorization: "bearer ".concat(token),
                        },
                    })];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_52 = _d.sent();
                if (err_52.response && err_52.response.data) {
                    return [2 /*return*/, rejectWithValue({
                            err: err_52.response.data,
                            status: err_52.response.status,
                        })];
                }
                else {
                    return [2 /*return*/, rejectWithValue({
                            err: "Network Error",
                        })];
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
var dashboardSlice = createSlice({
    name: "dashboard",
    initialState: initialState,
    reducers: {
        SetState: function (state, action) {
            var _a = action.payload, field = _a.field, value = _a.value;
            state[field] = value;
        },
        ClearState: function (state) {
            return __assign(__assign({}, initialState), { routeLoading: state.routeLoading });
        },
    },
    extraReducers: function (builder) {
        builder
            .addCase(gettingBodyCorporateUsers.pending, function (state) {
            state.loading = true;
            state.currentBodyCorporateUser = {};
        })
            .addCase(gettingBodyCorporateUsers.fulfilled, function (state, action) {
            state.loading = false;
            state.done = true;
            state.bodyCorporateUsers = action.payload;
            state.currentBodyCorporateUser = {};
        })
            .addCase(gettingBodyCorporateUsers.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
            state.currentBodyCorporateUser = {};
        })
            .addCase(gettingStaffs.pending, function (state) {
            state.loading = true;
            state.currentStaff = {};
        })
            .addCase(gettingStaffs.fulfilled, function (state, action) {
            state.loading = false;
            state.currentStaff = {};
            state.staffs = action.payload;
        })
            .addCase(gettingStaffs.rejected, function (state, action) {
            state.loading = false;
            state.currentStaff = {};
            state.err = action.payload.err;
        })
            .addCase(gettingPreSignedUrl.pending, function (state) {
            state.loading = true;
        })
            .addCase(gettingPreSignedUrl.fulfilled, function (state, action) {
            state.loading = false;
            state.done = true;
            state.preSignedUrl = action.payload.url;
        })
            .addCase(gettingPreSignedUrl.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(gettingProfileImage.pending, function (state) {
            state.loading = true;
        })
            .addCase(gettingProfileImage.fulfilled, function (state, action) {
            state.loading = false;
            state.done = true;
            state.profileImage = action.payload;
        })
            .addCase(gettingProfileImage.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(uploadingProfileImage.pending, function (state) {
            state.loading = true;
        })
            .addCase(uploadingProfileImage.fulfilled, function (state, action) {
            state.loading = false;
            state.done = true;
        })
            .addCase(uploadingProfileImage.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(resetPassword.pending, function (state) {
            state.loading = true;
        })
            .addCase(resetPassword.fulfilled, function (state, action) {
            state.loading = false;
            state.done = true;
            state.bodyCorporateUsers = action.payload;
        })
            .addCase(resetPassword.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(gettingContractors.pending, function (state) {
            state.loading = true;
            state.currentContractor = {};
        })
            .addCase(gettingContractors.fulfilled, function (state, action) {
            state.loading = false;
            state.done = true;
            state.contractors = action.payload;
            state.currentContractor = {};
        })
            .addCase(gettingContractors.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
            state.currentContractor = {};
        })
            .addCase(gettingcategories.pending, function (state) {
            state.loading = true;
            state.currentCategory = {};
        })
            .addCase(gettingcategories.fulfilled, function (state, action) {
            state.loading = false;
            state.categories = action.payload;
            state.currentCategory = {};
        })
            .addCase(gettingcategories.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
            state.currentCategory = {};
        })
            .addCase(gettingFunds.pending, function (state) {
            state.loading = true;
            state.currentFund = {};
        })
            .addCase(gettingFunds.fulfilled, function (state, action) {
            state.loading = false;
            state.currentFund = {};
            state.funds = action.payload;
        })
            .addCase(gettingFunds.rejected, function (state, action) {
            state.loading = false;
            state.currentFund = {};
            state.err = action.payload.err;
        })
            .addCase(gettingComments.pending, function (state) {
            state.loading = true;
        })
            .addCase(gettingComments.fulfilled, function (state, action) {
            state.loading = false;
            state.done = true;
            state.comments = action.payload;
        })
            .addCase(gettingComments.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(gettingNotifications.pending, function (state) {
            state.loading = true;
        })
            .addCase(gettingNotifications.fulfilled, function (state, action) {
            state.loading = false;
            state.done = true;
            state.notifications = action.payload;
        })
            .addCase(gettingNotifications.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(clearNotifications.pending, function (state) {
            state.loading = true;
        })
            .addCase(clearNotifications.fulfilled, function (state, action) {
            state.loading = false;
            state.done = true;
            state.notifications = action.payload;
        })
            .addCase(clearNotifications.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(gettingBodyCorporates.pending, function (state) {
            state.loading = true;
            state.currentBodyCorporate = {};
        })
            .addCase(gettingBodyCorporates.fulfilled, function (state, action) {
            state.loading = false;
            state.done = true;
            state.currentBodyCorporate = {};
            state.bodyCorporates = action.payload;
        })
            .addCase(gettingBodyCorporates.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
            state.currentBodyCorporate = {};
        })
            .addCase(gettingAllInvoices.pending, function (state) {
            state.loading = true;
            state.invoiceLoading = true;
            state.newInvoices = { rows: [], count: 0, fullCount: 0 };
            state.currentInvoice = null;
        })
            .addCase(gettingAllInvoices.fulfilled, function (state, action) {
            state.loading = false;
            state.invoiceLoading = false;
            state.done = true;
            state.newInvoices = action.payload;
            state.currentInvoice = null;
        })
            .addCase(gettingAllInvoices.rejected, function (state, action) {
            state.loading = false;
            state.invoiceLoading = false;
            state.err = action.payload.err;
            state.currentInvoice = null;
        })
            .addCase(gettingBodycorporateInvoices.pending, function (state) {
            state.loading = true;
            state.invoiceLoading = true;
            state.currentInvoice = null;
        })
            .addCase(gettingBodycorporateInvoices.fulfilled, function (state, action) {
            state.loading = false;
            state.invoiceLoading = false;
            state.done = true;
            state.newInvoices = action.payload;
            state.currentInvoice = null;
        })
            .addCase(gettingBodycorporateInvoices.rejected, function (state, action) {
            state.loading = false;
            state.invoiceLoading = false;
            state.err = action.payload.err;
            state.currentInvoice = null;
        })
            .addCase(getUserAcceptedfilterList.pending, function (state) {
            state.loading = true;
        })
            .addCase(getUserAcceptedfilterList.fulfilled, function (state, action) {
            state.loading = false;
            state.contractors.rows = action.payload.contractors;
            state.categories.rows = action.payload.categories;
            state.funds.rows = action.payload.funds;
        })
            .addCase(getUserAcceptedfilterList.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(gettingSingleNewInvoices.pending, function (state) {
            state.loading = true;
            state.currentInvoice = null;
        })
            .addCase(gettingSingleNewInvoices.fulfilled, function (state, action) {
            state.loading = false;
            state.done = true;
            state.currentInvoice = action.payload.invoice;
        })
            .addCase(gettingSingleNewInvoices.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(gettingSingleBodyCorporateUser.pending, function (state) {
            state.loading = true;
        })
            .addCase(gettingSingleBodyCorporateUser.fulfilled, function (state, action) {
            state.loading = false;
            state.done = true;
            state.currentBodyCorporateUser = action.payload.bodyCorporateUser;
        })
            .addCase(gettingSingleBodyCorporateUser.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(gettingBodyCorporatesByUser.pending, function (state) {
            state.loading = true;
        })
            .addCase(gettingBodyCorporatesByUser.fulfilled, function (state, action) {
            state.loading = false;
            state.done = true;
            state.bodyCorporates.rows = action.payload.bodyCorporates;
        })
            .addCase(gettingBodyCorporatesByUser.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(gettingSingleContractor.pending, function (state) {
            state.loading = true;
        })
            .addCase(gettingSingleContractor.fulfilled, function (state, action) {
            state.loading = false;
            state.done = true;
            state.currentContractor = action.payload.contractor;
        })
            .addCase(gettingSingleContractor.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(gettingSingleStaff.pending, function (state) {
            state.loading = true;
        })
            .addCase(gettingSingleStaff.fulfilled, function (state, action) {
            state.loading = false;
            state.currentStaff = action.payload.staff;
        })
            .addCase(gettingSingleStaff.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(gettingSingleCategory.pending, function (state) {
            state.loading = true;
        })
            .addCase(gettingSingleCategory.fulfilled, function (state, action) {
            state.loading = false;
            state.currentCategory = action.payload.category;
        })
            .addCase(gettingSingleCategory.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(gettingSingleFund.pending, function (state) {
            state.loading = true;
        })
            .addCase(gettingSingleFund.fulfilled, function (state, action) {
            state.loading = false;
            state.currentFund = action.payload.fund;
        })
            .addCase(gettingSingleFund.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(gettingSingleBodyCorporate.pending, function (state) {
            state.loading = true;
        })
            .addCase(gettingSingleBodyCorporate.fulfilled, function (state, action) {
            state.loading = false;
            state.done = true;
            state.currentBodyCorporate = action.payload.bodyCorporate;
        })
            .addCase(gettingSingleBodyCorporate.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(updateBodyValidate.pending, function (state) {
            state.loading = true;
        })
            .addCase(updateBodyValidate.fulfilled, function (state, action) {
            state.loading = false;
            state.done = true;
        })
            .addCase(updateBodyValidate.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(enableStaff.pending, function (state) {
        })
            .addCase(enableStaff.fulfilled, function (state, action) {
            state.done = true;
        })
            .addCase(enableStaff.rejected, function (state, action) {
            state.err = action.payload.err;
        })
            .addCase(addingContractor.pending, function (state) {
            state.loading = true;
        })
            .addCase(addingContractor.fulfilled, function (state, action) {
            state.loading = false;
        })
            .addCase(addingContractor.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(getFilterOptions.pending, function (state) {
            state.loading = true;
        })
            .addCase(getFilterOptions.fulfilled, function (state, action) {
            state.loading = false;
            state.filterData = action.payload;
            state.contractors.rows = action.payload.typefilter === 'selectedBodyCorporates' ? action.payload.data : state.contractors.rows;
            state.funds.rows = action.payload.typefilter === 'selectedContractors' ? action.payload.data : state.funds.rows;
            state.categories.rows = action.payload.typefilter === 'selectedFunds' ? action.payload.data : state.categories.rows;
        })
            .addCase(getFilterOptions.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(getFilterStartOptions.pending, function (state) {
            state.loading = true;
        })
            .addCase(getFilterStartOptions.fulfilled, function (state, action) {
            state.loading = false;
            // state.filterData = action.payload;
            state.contractors.rows = action.payload.contractors;
            state.funds.rows = action.payload.funds;
            state.categories.rows = action.payload.categories;
        })
            .addCase(getFilterStartOptions.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(sendingInvoiceGroup.pending, function (state) {
            state.loading = true;
        })
            .addCase(sendingInvoiceGroup.fulfilled, function (state, action) {
            state.loading = false;
        })
            .addCase(sendingInvoiceGroup.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(gettingStaffAcceptedInvoices.pending, function (state) {
            state.loading = true;
        })
            .addCase(gettingStaffAcceptedInvoices.fulfilled, function (state, action) {
            state.loading = false;
            state.newInvoices = action.payload;
        })
            .addCase(gettingStaffAcceptedInvoices.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(gettingUserAcceptedInvoices.pending, function (state) {
            state.loading = true;
        })
            .addCase(gettingUserAcceptedInvoices.fulfilled, function (state, action) {
            state.loading = false;
            state.newInvoices = action.payload;
        })
            .addCase(gettingUserAcceptedInvoices.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(gettingStaffReports.pending, function (state) {
            state.loading = true;
            state.invoiceLoading = true;
        })
            .addCase(gettingStaffReports.fulfilled, function (state, action) {
            var _a = action.payload, rows = _a.rows, totalPages = _a.totalPages, count = _a.count, fullCount = _a.fullCount;
            state.loading = false;
            state.newInvoices = __assign(__assign({}, action.payload), { fullCount: fullCount, count: count, rows: __spreadArray(__spreadArray([], state.newInvoices.rows, true), rows, true) });
            state.invoiceLoading = false;
            state.totalPages = totalPages;
        })
            .addCase(gettingStaffReports.rejected, function (state, action) {
            state.loading = false;
            state.invoiceLoading = false;
            state.err = action.payload.err;
        })
            .addCase(changePendingInvoiceStatus.pending, function (state) {
            state.loading = true;
        })
            .addCase(changePendingInvoiceStatus.fulfilled, function (state, action) {
            state.loading = false;
        })
            .addCase(changePendingInvoiceStatus.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(changePendingInvoiceStatusByuser.pending, function (state) {
            state.loading = true;
        })
            .addCase(changePendingInvoiceStatusByuser.fulfilled, function (state, action) {
            state.loading = false;
        })
            .addCase(changePendingInvoiceStatusByuser.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(remainderInvoiceGroup.pending, function (state) {
            state.loading = true;
        })
            .addCase(remainderInvoiceGroup.fulfilled, function (state, action) {
            state.loading = false;
        })
            .addCase(remainderInvoiceGroup.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(addingComment.pending, function (state) {
            state.loading = true;
        })
            .addCase(addingComment.fulfilled, function (state, action) {
            state.loading = false;
        })
            .addCase(addingComment.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(addingCategory.pending, function (state) {
            state.loading = true;
        })
            .addCase(addingCategory.fulfilled, function (state, action) {
            state.loading = false;
            state.done = true;
        })
            .addCase(addingCategory.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(addingFund.pending, function (state) {
            state.loading = true;
        })
            .addCase(addingFund.fulfilled, function (state, action) {
            state.loading = false;
            state.done = true;
        })
            .addCase(addingFund.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(addBodyCorporateUser.pending, function (state) {
            state.loading = true;
        })
            .addCase(addBodyCorporateUser.fulfilled, function (state, action) {
            state.loading = false;
            state.done = true;
        })
            .addCase(addBodyCorporateUser.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(addStaff.pending, function (state) {
            state.loading = true;
        })
            .addCase(addStaff.fulfilled, function (state, action) {
            state.loading = false;
            state.done = true;
        })
            .addCase(addStaff.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(addNewInvoice.pending, function (state) {
            state.loading = true;
            state.invoiceLoading = true;
        })
            .addCase(addNewInvoice.fulfilled, function (state, action) {
            state.loading = false;
            state.done = true;
            state.invoiceLoading = false;
        })
            .addCase(addNewInvoice.rejected, function (state, action) {
            state.loading = false;
            state.invoiceLoading = false;
            state.err = action.payload.err;
        })
            .addCase(addingBodyCorporate.pending, function (state) {
            state.loading = true;
        })
            .addCase(addingBodyCorporate.fulfilled, function (state, action) {
            state.loading = false;
            state.done = true;
        })
            .addCase(addingBodyCorporate.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(updatingcontractor.pending, function (state) {
            state.loading = true;
        })
            .addCase(updatingcontractor.fulfilled, function (state, action) {
            state.loading = false;
            state.done = true;
        })
            .addCase(updatingcontractor.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(updatingCategory.pending, function (state) {
            state.loading = true;
        })
            .addCase(updatingCategory.fulfilled, function (state, action) {
            state.loading = false;
            state.done = true;
        })
            .addCase(updatingCategory.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(updatingFund.pending, function (state) {
            state.loading = true;
        })
            .addCase(updatingFund.fulfilled, function (state, action) {
            state.loading = false;
            state.done = true;
        })
            .addCase(updatingFund.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(updatingBodyCorporateUser.pending, function (state) {
            state.loading = true;
        })
            .addCase(updatingBodyCorporateUser.fulfilled, function (state, action) {
            state.loading = false;
            state.done = true;
        })
            .addCase(updatingBodyCorporateUser.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(updatingComment.pending, function (state) {
            state.loading = true;
        })
            .addCase(updatingComment.fulfilled, function (state, action) {
            state.loading = false;
            state.done = true;
        })
            .addCase(updatingComment.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(deleteInvoice.pending, function (state) {
            state.loading = true;
        })
            .addCase(deleteInvoice.fulfilled, function (state, action) {
            state.loading = false;
            state.done = true;
        })
            .addCase(deleteInvoice.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(updatingStaff.pending, function (state) {
            state.loading = true;
        })
            .addCase(updatingStaff.fulfilled, function (state, action) {
            state.loading = false;
            state.done = true;
        })
            .addCase(updatingStaff.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(updatingBodyCorporate.pending, function (state) {
            state.loading = true;
        })
            .addCase(updatingBodyCorporate.fulfilled, function (state, action) {
            state.loading = false;
            state.done = true;
        })
            .addCase(updatingBodyCorporate.rejected, function (state, action) {
            state.loading = false;
            state.err = action.payload.err;
        })
            .addCase(updatingInvoice.pending, function (state) {
            state.loading = true;
            state.invoiceLoading = true;
        })
            .addCase(updatingInvoice.fulfilled, function (state, action) {
            state.loading = false;
            state.done = true;
            state.invoiceLoading = false;
        })
            .addCase(updatingInvoice.rejected, function (state, action) {
            state.loading = false;
            state.invoiceLoading = false;
            state.err = action.payload.err;
        });
    },
});
export var SetState = (_a = dashboardSlice.actions, _a.SetState), ClearState = _a.ClearState;
export default dashboardSlice.reducer;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faCircleRight } from '@fortawesome/free-regular-svg-icons';
import { useSelector } from 'react-redux';
var ButtonSection = function (_a) {
    var addPath = _a.addPath, activeTab = _a.activeTab, setSendbutton = _a.setSendbutton, selectedRows = _a.selectedRows, setMessageBarProps = _a.setMessageBarProps, setShowBar = _a.setShowBar;
    var roles = useSelector(function (state) { return state.auth; }).roles;
    return (_jsxs("div", { className: "button-section text-md-end text-center", children: [roles === "ROLE_BC_USER" && activeTab === "pending" && (_jsxs("button", { className: "btn btn-light-blue-large", onClick: function () {
                    if ((selectedRows === null || selectedRows === void 0 ? void 0 : selectedRows.length) > 0) {
                        setSendbutton('accept-invoices');
                        setShowBar(false);
                    }
                    else {
                        setMessageBarProps({
                            type: "info",
                            message: "Please select any invoice"
                        });
                        setShowBar(true);
                    }
                }, children: ["Accept Invoice ", _jsx("i", { className: "icon-plus icons icon-right" }), _jsx(FontAwesomeIcon, { icon: faCircleRight, size: 'lg' })] })), roles === "ROLE_STAFF" && (_jsxs(Link, { className: "btn btn-light-blue-large", to: addPath, children: ["Add New Invoice ", _jsx("i", { className: "icon-plus icons icon-right" }), _jsx(FontAwesomeIcon, { icon: faPlus })] })), activeTab === 'pending' && roles !== "ROLE_BC_USER" && (_jsxs("button", { className: "remainderInvoicesGroup btn btn-gold", onClick: function () {
                    if ((selectedRows === null || selectedRows === void 0 ? void 0 : selectedRows.length) > 0) {
                        setSendbutton('send-reminder');
                        setShowBar(false);
                    }
                    else {
                        setMessageBarProps({
                            type: "info",
                            message: "Please select any invoice"
                        });
                        setShowBar(true);
                    }
                }, children: ["Send Reminder ", _jsx("i", { className: "icon-arrow-right-circle icons icon-right" }), _jsx(FontAwesomeIcon, { icon: faCircleRight, size: 'lg' })] })), activeTab === 'new' && roles !== "ROLE_BC_USER" && (_jsxs("button", { className: "remainderInvoicesGroup btn btn-blue", onClick: function () {
                    if ((selectedRows === null || selectedRows === void 0 ? void 0 : selectedRows.length) > 0) {
                        setSendbutton('send-invoice');
                        setShowBar(false);
                    }
                    else {
                        setMessageBarProps({
                            type: "info",
                            message: "Please select any invoice"
                        });
                        setShowBar(true);
                    }
                }, children: ["Send Invoice ", _jsx("i", { className: "icon-arrow-right-circle icons icon-right" }), _jsx(FontAwesomeIcon, { icon: faCircleRight, size: 'lg' })] }))] }));
};
export default ButtonSection;

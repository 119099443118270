import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AuthRoute from './routes/AuthRoute';
import AppRoute from './routes/AppRoute';
import PageNotFound from './pages/PageNotFound';
import PublicRoute from './routes/publicRoute';
import PrivateRoute from './routes/privateRoute';
import './pages/dashboard/_main.scss';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'bootstrap/dist/css/bootstrap.css';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = "//unpkg.com/pdfjs-dist@".concat(pdfjs.version, "/build/pdf.worker.min.mjs");
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
// import './app.css';
var App = function () {
    return (_jsx(BrowserRouter, { children: _jsxs(Routes, { children: [_jsxs(Route, { path: "auth", element: _jsx(PublicRoute, {}), children: [_jsx(Route, { path: "*", element: _jsx(AuthRoute, {}) }), _jsx(Route, { path: "", element: _jsx(PageNotFound, {}) })] }), _jsx(Route, { path: "*", element: _jsx(PrivateRoute, {}), children: _jsx(Route, { path: "*", element: _jsx(AppRoute, {}) }) })] }) }));
};
export default App;

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { gettingProfileImage } from '../../redux/reducers/dashboard';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Document, Page } from 'react-pdf';
var REACT_APP_INVOICE_PDF_BUCKET_NAME_S3 = process.env.REACT_APP_INVOICE_PDF_BUCKET_NAME_S3;
function PdfDisplay() {
    var _a = useState(''), pdfUrl = _a[0], setPdfUrl = _a[1];
    var dispatch = useDispatch();
    var filepath = useParams().filepath;
    var _b = useState(), numPages = _b[0], setNumPages = _b[1];
    function onDocumentLoadSuccess(_a) {
        var numPages = _a.numPages;
        setNumPages(numPages);
    }
    useEffect(function () {
        dispatch(gettingProfileImage({ fileName: decodeURIComponent("".concat(filepath, ".pdf")), bucketName: REACT_APP_INVOICE_PDF_BUCKET_NAME_S3 })).then(function (res) {
            if (res === null || res === void 0 ? void 0 : res.payload) {
                var linkSource = "data:application/pdf;base64,".concat(res === null || res === void 0 ? void 0 : res.payload.data);
                setPdfUrl(linkSource);
            }
        });
    }, [dispatch]);
    return (pdfUrl ? (_jsx("div", { className: "pdf-container", children: _jsx(Document, { file: pdfUrl, onLoadSuccess: onDocumentLoadSuccess, children: Array === null || Array === void 0 ? void 0 : Array.from(new Array(numPages), function (el, index) { return (_jsxs(_Fragment, { children: [_jsx(Page, { pageNumber: index + 1 }, "page_".concat(index + 1)), _jsxs("p", { children: ["Page ", index + 1, " of ", numPages] })] })); }) }) })) : (_jsx("p", { children: "Loading PDF..." })));
}
export default PdfDisplay;

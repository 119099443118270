var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
var AcceptInvoicesModal = function (_a) {
    var onSendInvoice = _a.onSendInvoice, sendbutton = _a.sendbutton, setSendbutton = _a.setSendbutton, onDeclineInvoice = _a.onDeclineInvoice, onAcceptInvoice = _a.onAcceptInvoice, onReminderInvoice = _a.onReminderInvoice, onAbstrainInvoice = _a.onAbstrainInvoice, onAcceptMultipleInvoices = _a.onAcceptMultipleInvoices;
    var _b = useForm(), register = _b.register, handleSubmit = _b.handleSubmit, reset = _b.reset, errors = _b.formState.errors;
    var _c = useState(false), show = _c[0], setShow = _c[1];
    useEffect(function () {
        if (sendbutton) {
            handleShow();
        }
    }, [sendbutton]);
    var handleClose = function () {
        setShow(false);
        setSendbutton('');
    };
    var handleShow = function () { return setShow(true); };
    var defaultAddComment = function (data) {
        onDeclineInvoice(data === null || data === void 0 ? void 0 : data.text);
        handleClose();
    };
    var renderModalHeader = function () {
        if (sendbutton === 'accept-invoice') {
            return 'Are you sure you want to accept this invoice?';
        }
        else if (sendbutton === 'decline-invoice') {
            return 'Are you sure you want to decline this invoice?';
        }
        else if (sendbutton === 'send-invoice') {
            return 'Are you sure you want to send these invoices?';
        }
        else if (sendbutton === 'send-reminder') {
            return 'Are you sure you want to send a reminder?';
        }
        else if (sendbutton === 'abstrain-invoice') {
            return 'Are you sure you want to abstain this invoice?';
        }
        else if (sendbutton === 'accept-invoices') {
            return 'Are you sure you want to accept these invoices?';
        }
    };
    var handleActionButtonClick = function () {
        if (sendbutton === 'send-invoice') {
            onSendInvoice();
            handleClose();
        }
        else if (sendbutton === 'accept-invoice') {
            onAcceptInvoice();
            handleClose();
        }
        else if (sendbutton === 'send-reminder') {
            onReminderInvoice();
            handleClose();
        }
        else if (sendbutton === 'abstrain-invoice') {
            onAbstrainInvoice();
            handleClose();
        }
        else if (sendbutton === 'accept-invoices') {
            onAcceptMultipleInvoices();
            handleClose();
        }
    };
    return (_jsx(_Fragment, { children: _jsxs(Modal, { show: show, onHide: handleClose, children: [_jsx(Modal.Header, { closeButton: true, children: renderModalHeader() }), sendbutton === 'decline-invoice' && (_jsxs(Modal.Body, { style: { maxHeight: '450px', overflowY: 'auto' }, children: [_jsx("textarea", __assign({}, register('text', { required: true }), { className: "form-control ".concat((errors === null || errors === void 0 ? void 0 : errors.text) ? 'is-invalid' : '') })), errors.text && _jsx("div", { className: "invalid-feedback", children: "Comment is required" })] })), _jsxs(Modal.Footer, { children: [_jsx(Button, { className: "btn btn-secondary", onClick: handleClose, children: "Cancel" }), _jsx(Button, { className: "btn btn-blue", onClick: sendbutton === 'decline-invoice' ? handleSubmit(defaultAddComment) : handleActionButtonClick, children: "Ok" })] })] }) }));
};
export default AcceptInvoicesModal;

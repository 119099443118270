import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Header from '../../pages/Common/Header';
import Footer from '../../pages/Common/Footer';
import BreadcrumbDashboard from '../../pages/Common/BreadcrumbDashboard';
var Layout = function (_a) {
    var children = _a.children, title = _a.title, InvoiceId = _a.InvoiceId;
    var linkProps = [
        { title: 'Home', href: "#" },
        { title: title, href: '#' },
    ];
    if (InvoiceId) {
        linkProps = [
            { title: 'Home', href: "#" },
            { title: title, href: '#' },
            { title: InvoiceId, href: '#' },
        ];
    }
    return (_jsxs("div", { children: [_jsx(Header, {}), _jsx("div", { className: "main-content", children: _jsxs("div", { className: "", children: [title !== '' && (_jsx(BreadcrumbDashboard, { linkProps: linkProps, title: title })), children] }) }), _jsx(Footer, {})] }));
};
export default Layout;

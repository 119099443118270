import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faEye, faFilePdf, faComment, faCloudDownloadAlt, faCheck, faRemove } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { gettingProfileImage } from '../../redux/reducers/dashboard';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
var REACT_APP_INVOICE_PDF_BUCKET_NAME_S3 = process.env.REACT_APP_INVOICE_PDF_BUCKET_NAME_S3;
var ActionDropdown = function (_a) {
    var invoiceId = _a.invoiceId, filepath = _a.filepath, handleAddComment = _a.handleAddComment, handlePdfShow = _a.handlePdfShow, invoiceNumber = _a.invoiceNumber, activeTab = _a.activeTab, handleAcceptInvoice = _a.handleAcceptInvoice, handleDeclineInvoice = _a.handleDeclineInvoice, handleAbstrainInvoice = _a.handleAbstrainInvoice, isUserIdPresent = _a.isUserIdPresent;
    var roles = useSelector(function (state) { return state.auth; }).roles;
    var _b = useState(false), dropdownOpen = _b[0], setDropdownOpen = _b[1];
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var _c = useState(''), linkSource = _c[0], SetLinkSource = _c[1];
    var toggleDropdown = function () { return setDropdownOpen(!dropdownOpen); };
    var handleDownload = function () {
        dispatch(gettingProfileImage({ fileName: decodeURIComponent(filepath), bucketName: REACT_APP_INVOICE_PDF_BUCKET_NAME_S3 })).then(function (res) {
            if (res === null || res === void 0 ? void 0 : res.payload) {
                var linkSource_1 = "data:application/pdf;base64,".concat(res === null || res === void 0 ? void 0 : res.payload.data);
                var link = document.createElement('a');
                link.href = linkSource_1;
                link.download = "".concat(invoiceNumber, ".pdf");
                link.click();
            }
        });
    };
    return (_jsx("div", { className: "btn-group", children: _jsxs(Dropdown, { show: dropdownOpen, onToggle: toggleDropdown, children: [_jsx(Dropdown.Toggle, { variant: "red", id: "dropdown-custom-components", className: "btn text-center", children: _jsx("span", { style: { textTransform: 'capitalize' }, children: "Actions" }) }), _jsxs(Dropdown.Menu, { className: "action-drop-menu dropdown-menu pull-right", children: [activeTab === 'pending' && !isUserIdPresent && (_jsxs(_Fragment, { children: [_jsxs(Dropdown.Item, { onClick: function () { return handleAcceptInvoice(invoiceId); }, children: [_jsx(FontAwesomeIcon, { icon: faCheck }), " Accept"] }), _jsxs(Dropdown.Item, { onClick: function () { return handleDeclineInvoice(invoiceId); }, children: [_jsx(FontAwesomeIcon, { icon: faRemove }), " Decline"] }), roles === "ROLE_BC_USER" &&
                                    (_jsxs(Dropdown.Item, { onClick: function () { return handleAbstrainInvoice(invoiceId); }, children: [_jsx(FontAwesomeIcon, { icon: faFile }), " Abstain"] }))] })), roles !== "ROLE_BC_USER" &&
                            (_jsx(Dropdown.Item, { children: _jsxs(Link, { to: "/staff/invoice/edit/".concat(invoiceId), style: { color: '#212529' }, className: 'dropdown-link-anchor', rel: "noopener noreferrer", children: [_jsx(FontAwesomeIcon, { icon: faPencilAlt }), " Edit"] }) })), _jsx(Dropdown.Item, { children: _jsxs(Link, { to: "/staff/invoice/view/".concat(invoiceId), style: { color: '#212529' }, className: 'dropdown-link-anchor', rel: "noopener noreferrer", children: [_jsx(FontAwesomeIcon, { icon: faEye }), " View"] }) }), _jsx(Dropdown.Item, { className: "view-pdf-action", children: _jsxs(Link, { style: { color: '#212529' }, className: 'dropdown-link-anchor', rel: "noopener noreferrer", to: "/invoices/".concat(filepath === null || filepath === void 0 ? void 0 : filepath.replace('.pdf', '')), onClick: function (e) {
                                    if (e.ctrlKey || e.metaKey || e.button === 1) {
                                    }
                                    else {
                                        e.preventDefault();
                                        handlePdfShow(invoiceId, invoiceNumber, filepath);
                                    }
                                }, children: [_jsx(FontAwesomeIcon, { icon: faFilePdf }), " View PDF"] }) }), _jsxs(Dropdown.Item, { className: "commentInlineModal", onClick: function () { return handleAddComment(invoiceId, invoiceNumber); }, children: [_jsx(FontAwesomeIcon, { icon: faComment }), " Comment"] }), _jsxs(Dropdown.Item, { onClick: handleDownload, children: [_jsx(FontAwesomeIcon, { icon: faCloudDownloadAlt }), " Download"] })] })] }) }));
};
export default ActionDropdown;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Container, Row, Col, Image, Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SignIn } from '../../redux/reducers/auth';
import { Link } from 'react-router-dom';
var logo = require('../../assets/images/logo.png');
// Access the URL of the imported image
var logoUrl = logo.default;
var Login = function () {
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var _a = useState({ email: '', password: '' }), signInObject = _a[0], setSignInObject = _a[1];
    var _b = useState(''), err = _b[0], setErr = _b[1];
    var _c = useSelector(function (state) { return state.auth; }), email = _c.email, password = _c.password, token = _c.token, loading = _c.loading;
    var handleInputChange = function (event) {
        var _a;
        var _b = event.target, name = _b.name, value = _b.value;
        setSignInObject(__assign(__assign({}, signInObject), (_a = {}, _a[name] = value, _a)));
    };
    var handleSubmit = function () {
        if ((signInObject === null || signInObject === void 0 ? void 0 : signInObject.email) !== '' && (signInObject === null || signInObject === void 0 ? void 0 : signInObject.password) !== '') {
            dispatch(SignIn({ email: signInObject === null || signInObject === void 0 ? void 0 : signInObject.email, password: signInObject === null || signInObject === void 0 ? void 0 : signInObject.password })).then(function (res) {
                var _a, _b;
                if (((_b = (_a = res === null || res === void 0 ? void 0 : res.payload) === null || _a === void 0 ? void 0 : _a.err) === null || _b === void 0 ? void 0 : _b.success) === false) {
                    setErr(res.payload.err.message);
                }
            });
        }
        else {
            setErr('Please enter email and password');
        }
    };
    return (_jsx("div", { className: 'main-content', children: _jsx(Container, { className: "h-100", children: _jsx("div", { className: "login", id: "login", children: _jsx(Row, { className: "justify-content-center align-items-center full-height-viewport", children: _jsx(Col, { md: 6, children: _jsx(Row, { className: "justify-content-center align-items-center h-100", children: _jsxs(Col, { md: 9, sm: 12, className: "text-center", children: [_jsx("div", { className: "security-logo", children: _jsx(Image, { src: logoUrl, alt: "Logo" }) }), _jsx("h1", { className: "login-title", children: "Invoice HQ by The Community Co." }), false ? ( // Replace `false` with actual authentication check
                                    _jsxs("div", { children: ["Logged in as ", email, " |", ' ', _jsx("a", { href: "#", onClick: function () { return navigate('/logout'); }, children: "Logout" })] })) : (_jsxs(_Fragment, { children: [false && ( // Replace `false` with actual condition to check previous session
                                            _jsx(Alert, { variant: "info", children: _jsx("div", { children: "Session message" }) })), _jsxs(Form, { onSubmit: function (e) { return e.preventDefault(); }, children: [_jsx(Form.Group, { controlId: "formBasicEmail", className: "mt-3", children: _jsx(Form.Control, { type: "email", placeholder: "Enter email", name: "email", value: signInObject.email, onChange: handleInputChange }) }), _jsx(Form.Group, { controlId: "formBasicPassword", className: "mt-3", children: _jsx(Form.Control, { type: "password", placeholder: "Password", name: "password", value: signInObject.password, onChange: handleInputChange }) }), err && _jsx(Alert, { variant: "danger", className: "mt-3", children: err }), _jsxs("div", { className: "d-flex justify-content-between align-items-center", children: [_jsx(Button, { type: "submit", className: "mt-3 btn-light-blue", onClick: handleSubmit, children: "Sign In" }), _jsx(Link, { to: '/auth/forgetpassword', children: "Forgot your password?" })] })] })] }))] }) }) }) }) }) }) }));
};
export default Login;

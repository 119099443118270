import { jsx as _jsx } from "react/jsx-runtime";
import Layout from '../../../layouts/dashboard';
import { useParams } from 'react-router-dom';
import React from 'react';
import UserInvoiceTabs from './UserInvoiceTabs';
var UserWrapper = function () {
    var _a = useParams(), name = _a.name, id = _a.id;
    return (_jsx(Layout, { title: name || '', InvoiceId: 'Invoices', children: _jsx(UserInvoiceTabs, {}) }));
};
export default UserWrapper;

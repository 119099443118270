import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';
import auth from "../reducers/auth";
import dashboard from "../reducers/dashboard";
// fields you want to whitelist
var persistConfig = {
    key: "root",
    storage: storage,
    whitelist: ["auth"],
};
var rootReducer = combineReducers({
    auth: auth,
    dashboard: dashboard,
});
var persistedReducer = persistReducer(persistConfig, rootReducer);
var store = configureStore({
    reducer: persistedReducer,
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware().concat(thunk, logger);
    },
});
export default store;
